import React from 'react';
import { ResponsiveLine } from '@nivo/line';
import {
    IconButton,
    Menu,
    MenuItem,
    Card,
    CardContent,
    Box,
    Typography,
    CircularProgress,
} from '@material-ui/core/';
import { DatePicker } from '@material-ui/pickers';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SearchIcon from '@material-ui/icons/Search';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const ItemSelected = (props) => {
    const { select, selectedDate, setSelectedDate, closeMenu, onSearch } = props;
    const classes = useStyles();
    switch (select) {
        case 'year':
            return (
                <div className={classes.divSelected}>
                    <Typography variant='subtitle1' className={classes.title2}>
                        Selected year
                    </Typography>
                    <div className={classes.divDatePicker}>
                        <DatePicker
                            disableFuture
                            id='selected_date'
                            openTo='year'
                            format='yyyy'
                            label='Selected year '
                            views={['year']}
                            value={selectedDate}
                            inputVariant='outlined'
                            onChange={setSelectedDate}
                        />
                        <IconButton onClick={onSearch}>
                            <SearchIcon />
                        </IconButton>
                    </div>
                    <IconButton aria-label='settings' onClick={closeMenu} className={classes.back}>
                        <ArrowBackIcon />
                    </IconButton>
                </div>
            );
        case 'month':
            return (
                <div className={classes.divSelected}>
                    <Typography variant='subtitle1' className={classes.title2}>
                        Selected year and month
                    </Typography>
                    <div className={classes.divDatePicker}>
                        <DatePicker
                            disableFuture
                            id='selected_date'
                            openTo='year'
                            format='MM/yyyy'
                            label='Selected month '
                            views={['year', 'month']}
                            value={selectedDate}
                            inputVariant='outlined'
                            onChange={setSelectedDate}
                        />
                        <IconButton onClick={onSearch}>
                            <SearchIcon />
                        </IconButton>
                    </div>
                    <IconButton aria-label='settings' onClick={closeMenu} className={classes.back}>
                        <ArrowBackIcon />
                    </IconButton>
                </div>
            );
        case 'date':
            return (
                <div className={classes.divSelected}>
                    <Typography variant='subtitle1' className={classes.title2}>
                        Selected date
                    </Typography>
                    <div className={classes.divDatePicker}>
                        <DatePicker
                            disableFuture
                            id='selected_date'
                            openTo='year'
                            format='dd/MM/yyyy'
                            label='Selected date '
                            views={['year', 'month', 'date']}
                            value={selectedDate}
                            inputVariant='outlined'
                            onChange={setSelectedDate}
                        />
                        <IconButton onClick={onSearch}>
                            <SearchIcon />
                        </IconButton>
                    </div>
                    <IconButton aria-label='settings' onClick={closeMenu} className={classes.back}>
                        <ArrowBackIcon />
                    </IconButton>
                </div>
            );
        case 'loading':
            return (
                <div className={classes.divLoading}>
                    <CircularProgress />
                </div>
            );
        default:
            return (
                <div className={classes.divLoading}>
                    <Typography variant='subtitle1' className={classes.title2}>
                        NO HAVE DATA
                    </Typography>
                </div>
            );
    }
};
const View = (props) => {
    const {
        colors,
        data,
        anchorEl,
        openMenu,
        closeMenu,
        selectedItemMenu,
        itemMenu,
        selectedDate,
        setSelectedDate,
        onSearch,
        stringDate,
    } = props;
    const classes = useStyles();

    return (
        <Card className={classes.root}>
            <CardContent style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
                <Box className={classes.row}>
                    <Typography variant='subtitle1' className={classes.title}>
                        TOTAL CALLS TO {stringDate}
                    </Typography>
                    <IconButton aria-label='settings' onClick={openMenu}>
                        <MoreVertIcon />
                    </IconButton>
                    <Menu
                        id='simple-menu'
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={closeMenu}
                    >
                        <MenuItem onClick={() => selectedItemMenu('year')}>Year</MenuItem>
                        <MenuItem onClick={() => selectedItemMenu('month')}>Month</MenuItem>
                        <MenuItem onClick={() => selectedItemMenu('date')}>date</MenuItem>
                    </Menu>
                </Box>
                <div style={{ display: 'flex', flex: 5, height: 300 }}>
                    {itemMenu === '' ? (
                        <ResponsiveLine
                            data={data}
                            margin={{ top: 30, right: 50, bottom: 30, left: 30 }}
                            xScale={{ type: 'point' }}
                            yScale={{
                                type: 'linear',
                                min: 'auto',
                                max: 'auto',
                                stacked: false,
                                reverse: false,
                            }}
                            curve='natural'
                            axisTop={null}
                            axisRight={null}
                            axisBottom={{
                                orient: 'bottom',
                                tickSize: 5,
                                tickPadding: 5,
                                tickRotation: 0,
                                legendOffset: 36,
                                legendPosition: 'middle',
                            }}
                            axisLeft={{
                                orient: 'left',
                                tickSize: 5,
                                tickPadding: 5,
                                tickRotation: 0,
                                legendOffset: -40,
                                legendPosition: 'middle',
                            }}
                            enableSlices='x'
                            sliceTooltip={({ slice }) => {
                                return (
                                    <div
                                        style={{
                                            background: 'white',
                                            padding: '9px 12px',
                                            border: '1px solid #ccc',
                                        }}
                                    >
                                        <div>
                                            <strong>Date:</strong> {slice.points[0].data.x}
                                        </div>
                                        {slice.points.map((point) => (
                                            <div
                                                key={point.id}
                                                style={{
                                                    color: point.serieColor,
                                                    padding: '3px 0',
                                                }}
                                            >
                                                <strong>{point.serieId}</strong> [
                                                {point.data.yFormatted}]
                                            </div>
                                        ))}
                                    </div>
                                );
                            }}
                            colors={(d) => colors[d.id]}
                            pointSize={10}
                            pointColor={{ theme: 'background' }}
                            pointBorderWidth={2}
                            pointBorderColor={{ from: 'serieColor' }}
                            pointLabelYOffset={-12}
                            useMesh={true}
                            legends={[]}
                        />
                    ) : (
                        <ItemSelected
                            select={itemMenu}
                            selectedDate={selectedDate}
                            setSelectedDate={setSelectedDate}
                            closeMenu={closeMenu}
                            onSearch={onSearch}
                        />
                    )}
                </div>
            </CardContent>
        </Card>
    );
};
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        margin: 10,
        alignSelf: 'stretch',
        alignItems: 'stretch',
        height: 400,
    },
    row: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
    },
    title: {
        display: 'flex',
        flex: 1,
        alignSelf: 'center',
        color: 'black',
        fontWeight: '500',
    },
    center: {
        display: 'flex',
        flex: 1,
    },
    divSelected: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'space-around',
    },
    title2: {
        alignSelf: 'center',
        color: 'black',
        fontWeight: '500',
    },
    divDatePicker: {
        display: 'flex',
        justifyContent: 'space-evenly',
    },
    back: {
        maxWidth: 60,
    },
    divLoading: {
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
}));
View.propTypes = {};
View.defaultProps = {};

export default View;
