import { USER_LIST_COMPLETE, USER_DATA_COMPLETE } from '../../consts/actionTypes';
const initialState = {
    userList: [],
    userData: [],
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case USER_LIST_COMPLETE:
            return {
                ...state,
                userList: action.payload,
            };

        case USER_DATA_COMPLETE:
            return {
                ...state,
                userData: action.payload,
            };
        default:
            return { ...state };
    }
};
export default reducer;
