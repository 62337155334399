import React from 'react';
import { makeStyles } from '@material-ui/core/';
import AudioPlayer from 'material-ui-audio-player';

const usePlayerStyles = makeStyles(() => {
    return {
        playIcon: {
            color: '#00affe',
            '&:hover': {
                color: '#4dc6ff',
            },
        },
        pauseIcon: {
            color: '#009de6',
            '&:hover': {
                color: '#4dc6ff',
            },
        },
        volumeIcon: {
            color: '#00affe',
        },
        volumeSlider: {
            color: '#00affe',
        },
        progressTime: {
            color: 'black',
        },
        mainSlider: {
            color: '#00affe',
            '& .MuiSlider-rail': {
                color: '#00affe',
            },
            '& .MuiSlider-track': {
                color: '#00affe',
            },
            '& .MuiSlider-thumb': {
                color: '#00affe',
            },
        },
    };
});

const AudioPlayerCustom = (props) => {
    return (
        <AudioPlayer
            useStyles={usePlayerStyles}
            elevation={1}
            width={{ flex: 1 }}
            variation='primary'
            spacing={1}
            volume={false}
            {...props}
        />
    );
};
export default AudioPlayerCustom;
