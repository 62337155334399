import { put, call, takeLatest, select } from 'redux-saga/effects';
import {
    RECENTS_CALLS_LIST_START,
    RECENTS_CALLS_LIST_COMPLETE,
    PIE_DATA_CALLS_LIST_START,
    PIE_DATA_CALLS_LIST_COMPLETE,
    LINE_DATA_CALLS_LIST_START,
    LINE_DATA_CALLS_LIST_COMPLETE,
    LINE_DATA_CALLS_LIST_MONTH_START,
    RECENTS_CALL_LIST_FILTER,
    FETCH_CALL_RECORD_START,
    FETCH_CALL_RECORD_COMPLETE,
    FETCH_CALL_TRANSCRIPT_START,
    FETCH_CALL_TRANSCRIPT_COMPLETE,
} from '../../consts/actionTypes';
import { apiCall } from '../../consts/funtions';
import {
    doesFilterRequestAction,
    isLoadingViewAction,
    snackBarAction,
} from '../actions/utilitiesAction';
import {
    PIECHARTDATA,
    LINECHARTDATA,
    LINECHARTDATA_MONTH,
    RECENTS_ORG,
    CALLRECORD,
    CALL_TRANSCRIPT,
} from '../../consts/url';
import { tokenSelector, organizationSelector } from '../selectors';
import { errorHandler } from '../../consts/errorHandler';
import tz from '../../consts/timezone';

function* recentsCallListByExt({ payload }) {
    try {
        yield put(isLoadingViewAction(true));
        const tokenState = yield select(tokenSelector);
        const organization = yield select(organizationSelector);
        const myDate = new Date();
        const offSet = myDate.getTimezoneOffset() * -60 * 1000;
        const results = yield call(
            apiCall,
            `v2/${RECENTS_ORG}/${organization}/${payload}/${tz}`,
            null,
            tokenState,
            'GET'
        );

        switch (results.status) {
            case 200:
                yield put({
                    type: RECENTS_CALLS_LIST_COMPLETE,
                    payload: results.data.result,
                });
                break;
            case 204:
                yield put({ type: RECENTS_CALLS_LIST_COMPLETE, payload: [] });
                yield put(errorHandler(null, 'info', "Don't have recent calls."));
                break;
            default:
                break;
        }
    } catch (error) {
        yield put(errorHandler(error));
    }
    yield put(isLoadingViewAction(false));
}
function* getPieData({ start, end }) {
    yield put(doesFilterRequestAction(true));
    try {
        const tokenState = yield select(tokenSelector);
        const organization = yield select(organizationSelector);
        const myDate = new Date();
        const offSet = myDate.getTimezoneOffset() * -60 * 1000;
        const results = yield call(
            apiCall,
            `${PIECHARTDATA}/${organization}/${start}/${end}/${tz}`,
            null,
            tokenState,
            'GET'
        );
        switch (results.status) {
            case 200:
                yield put({
                    type: PIE_DATA_CALLS_LIST_COMPLETE,
                    payload: results.data,
                });
                break;
            case 204:
                yield put({ type: PIE_DATA_CALLS_LIST_COMPLETE, payload: [] });
                yield put(
                    errorHandler(null, 'info', `Dont have pie info between ${start} and ${end} `)
                );
                break;
            default:
                break;
        }
    } catch (error) {
        yield put(errorHandler(error));
    }
    yield put(doesFilterRequestAction(false));
}
function* getLineData({ payload }) {
    yield put(doesFilterRequestAction(true));

    try {
        const tokenState = yield select(tokenSelector);
        const organization = yield select(organizationSelector);
        const myDate = new Date();
        const offSet = myDate.getTimezoneOffset() * -60 * 1000;
        const results = yield call(
            apiCall,
            `${LINECHARTDATA}/${organization}/${payload}/${tz}`,
            null,
            tokenState,
            'GET'
        );
        switch (results.status) {
            case 200:
                yield put({
                    type: LINE_DATA_CALLS_LIST_COMPLETE,
                    payload: results.data,
                });
                break;
            case 204:
                yield put({ type: LINE_DATA_CALLS_LIST_COMPLETE, payload: [] });
                yield put(errorHandler(null, 'info', `Dont have Line info in ${payload}`));
                break;
            default:
                break;
        }
    } catch (error) {
        yield put(errorHandler(error));
    }

    yield put(doesFilterRequestAction(false));
}

function* getLineDataMonth({ year, month }) {
    try {
        const tokenState = yield select(tokenSelector);
        const organization = yield select(organizationSelector);
        const myDate = new Date();
        const offSet = myDate.getTimezoneOffset() * -60 * 1000;
        const results = yield call(
            apiCall,
            `${LINECHARTDATA_MONTH}/${organization}/${year}/${month}/${tz}`,
            null,
            tokenState,
            'GET'
        );
        switch (results.status) {
            case 200:
                yield put({
                    type: LINE_DATA_CALLS_LIST_COMPLETE,
                    payload: results.data,
                });
                break;
            case 204:
                yield put({ type: LINE_DATA_CALLS_LIST_COMPLETE, payload: [] });
                yield put(errorHandler(null, 'info', `Dont have Line info in ${month}/${year}`));
                break;
            default:
                break;
        }
    } catch (error) {
        yield put(errorHandler(error));
    }
}

function* recentsCallListFilter({ start, end, offset }) {
    yield put(doesFilterRequestAction(true));

    try {
        const tokenState = yield select(tokenSelector);
        const organization = yield select(organizationSelector);
        const myDate = new Date();
        // const offSet = myDate.getTimezoneOffset() * -60 * 1000;
        // fetch the data from the remote
        // destruct the object and get the result data only
        const {
            data: { result, total_pages },
        } = yield call(
            apiCall,
            `calls_inbound_outbound/${organization}/${start}/${end}/${tz}/${offset}/15`,
            null,
            tokenState,
            'GET'
        );
        // if there is enough data, update the redux store
        // otherwise show error
        if (result)
            yield put({ type: RECENTS_CALLS_LIST_COMPLETE, payload: { result, total_pages } });
        else {
            yield put(errorHandler(null, 'info', `Don't have recent call from ${start} to ${end}`));
        }
    } catch (error) {
        yield put(errorHandler(error));
    }
    yield put(doesFilterRequestAction(false));
}

function* fetchCallRecordAudio({ date, filename }) {
    try {
        const token = yield select(tokenSelector);
        const response = yield call(
            apiCall,
            `${CALLRECORD}/${date}/${filename}`,
            null,
            token,
            'GET'
        );

        yield put({ type: FETCH_CALL_RECORD_COMPLETE, payload: { data: response.data, filename } });
    } catch (error) {
        yield put(errorHandler(error));
    }
}

function* fetchCallTranscript({ date, filename }) {
    try {
        const token = yield select(tokenSelector);
        const response = yield call(
            apiCall,
            `${CALL_TRANSCRIPT}/${date}/${filename}`,
            null,
            token,
            'GET'
        );

        yield put({ type: FETCH_CALL_TRANSCRIPT_COMPLETE, payload: response.data });
    } catch (error) {
        yield put(errorHandler(error));
    }
}

export default function* calls() {
    yield takeLatest(RECENTS_CALLS_LIST_START, recentsCallListByExt);
    yield takeLatest(PIE_DATA_CALLS_LIST_START, getPieData);
    yield takeLatest(LINE_DATA_CALLS_LIST_START, getLineData);
    yield takeLatest(LINE_DATA_CALLS_LIST_MONTH_START, getLineDataMonth);
    yield takeLatest(RECENTS_CALL_LIST_FILTER, recentsCallListFilter);
    yield takeLatest(FETCH_CALL_RECORD_START, fetchCallRecordAudio);
    yield takeLatest(FETCH_CALL_TRANSCRIPT_START, fetchCallTranscript);
}
