import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { signOutAction } from '../../Redux/actions/signInAction';
import { userDataAction } from '../../Redux/actions/userAction';
import { isLoadingViewSelector } from '../../Redux/selectors';
import { userDataSelector } from '../../Redux/selectors';
import { useTheme, useMediaQuery } from '@material-ui/core';
import PropTypes from 'prop-types';
import View from './view';

const Index = (props) => {
    const { children } = props;
    const dispatch = useDispatch();
    const isLoadingViewState = useSelector((state) => isLoadingViewSelector(state));
    const [openDrawer, setOpenDrawer] = useState(true);
    const user = useSelector((state) => userDataSelector(state));
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const handleLogout = () => {
        dispatch(signOutAction());
    };

    useEffect(() => {
        if (isSmallScreen) {
            setOpenDrawer(false);
        }
    }, [isSmallScreen]);

    useEffect(() => {
        if (!user.length) dispatch(userDataAction());
    }, []);

    return (
        <View
            children={children}
            openDrawer={openDrawer}
            setOpenDrawer={setOpenDrawer}
            handleLogout={handleLogout}
            isLoadingViewState={isLoadingViewState}
            user={user}
        />
    );
};

Index.prototype = {
    children: PropTypes.element.isRequired,
};
export default Index;
