import { COLLAPSE_LOADING, GET_CALL_PROFILES_START } from '../../consts/actionTypes';

/**
 * A dispatcher for saga to get the call tracking data
 * @param {Object} params              - The object containing params as key-value pair
 * @param {String} params.callInfo     - CallInfo could be either 'callprofile' or 'calllog
 *                                     - callprofile will get  all the customer
 *                                     - calllog will get the each customer call logs
 * @param {String} params.status       - status could be I/ O/ M/ ALL
 * @param {String} params.extension    - did number
 * @param {Date}   params.date_from    - date_from
 * @param {Date}   params.date_to      - date_to
 * @param {Number} params.page         - page number
 * @param {Number} params.size         - size, offset
 * @returns
 */
export const getCallTrackingAction = (params) => ({
    type: GET_CALL_PROFILES_START,
    ...params,
});

/**
 * A dispatcher for showing the loading indicator
 * @param {String}   idx             - index of the element
 * @param {Boolean}  isCollapseOpen  - expand (true, false)
 */
export const collapseLoadingAction = (idx, isCollapseOpen) => ({
    type: COLLAPSE_LOADING,
    idx,
    isCollapseOpen,
});
