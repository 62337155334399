import React from 'react';
import { makeStyles, Box, Typography } from '@material-ui/core/';
import { ArrowDropUp } from '@material-ui/icons/';
import { ProgressBar, ActionCard } from '../../../../components';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
    stats: {
        flex: 1,
        alignSelf: 'center',
        color: '#fc5621',
        fontWeight: '500',
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
    },
    iconArrowUp: {
        alignSelf: 'center',
        color: 'green',
    },
    percent: {
        flex: 1,
        alignSelf: 'center',
        color: 'black',
        fontWeight: '500',
    },
}));

const BoxCall = (props) => {
    const classes = useStyles();
    const { titleBox, colorValue, valueCant, valueprogressBar, onClick, active } = props;

    return (
        <ActionCard title={titleBox} onClick={onClick} borderColor={active}>
            <Typography variant='h4' className={classes.stats} style={{ color: colorValue }}>
                {valueCant}
            </Typography>
            <ProgressBar barColor={colorValue} value={Number(valueprogressBar)} />
            <Box className={classes.row}>
                <ArrowDropUp className={classes.iconArrowUp} />
                <Typography variant='caption' className={classes.percent}>
                    {`${valueprogressBar}%`}
                </Typography>
            </Box>
        </ActionCard>
    );
};
BoxCall.propTypes = {
    titleBox: PropTypes.string.isRequired,
    colorValue: PropTypes.string.isRequired,
    valueCant: PropTypes.number.isRequired,
    valueprogressBar: PropTypes.string.isRequired,
};
BoxCall.defaultProps = {};
export default BoxCall;
