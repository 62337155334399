import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardContent, Typography, Box, CircularProgress } from '@material-ui/core/';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
    root: {
        flex: 1,
        margin: 10,
        alignSelf: 'stretch',
        alignItems: 'stretch',
        cursor: 'pointer',
        border: '2px solid #d9d9d9 !important'

    },
    red: {
        border: '2px solid #fd6817 !important'
    },
    green: {
        border: '2px solid #12b3ab !important'
    },
    blue: {
        border: '2px solid #586af8 !important'
    },
    pink: {
        border: '2px solid #d100b1 !important'
    },
    row: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
    },
    title: {
        flex: 1,
        alignSelf: 'center',
        color: 'black',
        fontWeight: '500',
    },
}));

const ActionCard = (props) => {
    const { title, children, isLoading, buttonMenu: ButtonMenu, onClick, borderColor } = props;
    const classes = useStyles();
    return (
        <Card className={`${classes.root} ${classes[borderColor]}`} onClick={onClick} elevation={0}>
            <CardContent style={{ flex: 1 }}>
                <Box className={classes.row}>
                    <Typography variant='subtitle1' className={classes.title}>
                    {title === 'MISSED' ? 'MISSED CALLS' : (title === 'INTERNAL' ? 'INTERNAL' : title)}
                    </Typography>
                    {ButtonMenu && <ButtonMenu />}
                </Box>
                {isLoading ? <CircularProgress /> : children}
            </CardContent>
        </Card>
    );
};
ActionCard.propTypes = {
    title: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    isLoading: PropTypes.bool,
    buttonMenu: PropTypes.any,
};
ActionCard.defaultProps = {
    isLoading: false,
};
export default ActionCard;
