import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    recentsCallListByExtSelector,
    extSelector,
    boxDataCallSelector,
    colorsSelector,
    doesFilterRequestSelector,
    pieDataCallSelector,
    callRecordFilesSelector,
    callTranscriptSelector,
    isLoadingCallTranscriptSelector,
} from '../../Redux/selectors';
import {
    pieDataListAction,
    recentsCallsFilterAction,
    callRecordFileAction,
    fetchCallTranscriptAction,
    isLoadingCallTranscriptAction,
} from '../../Redux/actions/callAction';
import { getDateInFormat } from '../../utils/';

import View from './view';
import { DateFilter } from '../../components';

const Index = () => {
    const recentsCallListState = useSelector((state) => recentsCallListByExtSelector(state));
    const pieDataCall = useSelector((state) => pieDataCallSelector(state));
    const colors = useSelector((state) => colorsSelector(state));
    const extState = useSelector((state) => extSelector(state));
    const boxCallArray = useSelector((state) => boxDataCallSelector(state));
    const dispatch = useDispatch();
    const [expanded, setExpanded] = useState(-1);
    const [selectedStartDate, setSelectedStartDate] = useState(new Date());
    const [selectedEndDate, setSelectedEndDate] = useState(new Date());
    const [openModalCall, setOpenModalCall] = useState(false);
    const isFilterRequested = useSelector((state) => doesFilterRequestSelector(state));
    const callRecordFiles = useSelector((state) => callRecordFilesSelector(state));
    const callTranscript = useSelector((state) => callTranscriptSelector(state));
    const isLoadingCallTranscript = useSelector((state) => isLoadingCallTranscriptSelector(state));
    const [callList, setCallList] = useState(recentsCallListState)
    const [summaryIndex, setSummaryIndex] = useState(0)
    const [nPage, setNPages] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)

    const countPie = pieDataCall.filter((pie) => pie.value !== 0);


    useEffect(() => {
        const today = new Date();
        const firstDateOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
        setSelectedStartDate(firstDateOfMonth);

        dispatch(
            pieDataListAction(getDateInFormat(firstDateOfMonth), getDateInFormat(selectedEndDate))
        );
        dispatch(
            recentsCallsFilterAction(
                getDateInFormat(firstDateOfMonth),
                getDateInFormat(selectedEndDate)
            )
        );
    }, []);

    useEffect(() => {
        pullCallSummary()
    }, [pieDataCall])


    const pullCallSummary = (calls=null, index = 0, currentPage=1) => {
        const indexOfLastPost = currentPage * 10
        const indexOfFirst = indexOfLastPost - 10
        const summaryArr = boxCallArray[index].results;
        setNPages(Math.ceil(summaryArr?.length / 10))
        setCallList({ 
            result: summaryArr?.slice(indexOfFirst, indexOfLastPost) 
        })

    }

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : -1);
    };

    const handleOnSearch = () => {
        dispatch(
            pieDataListAction(getDateInFormat(selectedStartDate), getDateInFormat(selectedEndDate))
        );
        dispatch(
            recentsCallsFilterAction(
                getDateInFormat(selectedStartDate),
                getDateInFormat(selectedEndDate)
            )
        );
    };

    const streamCallRecordhandler = (filename, date) => {
        date = date.split('/');
        // create pattern: 2022-05-12
        date = `${date[date.length - 1]}-${date[date.length - 3]}-${date[date.length - 2]}`;

        filename = filename.replace('/var/spool/asterisk/monitor/', '');
        filename = filename.split('/');
        filename = filename[filename.length - 1];
        console.log('[URL]', date, filename);
        dispatch(callRecordFileAction(date, filename));
    };

    const openModalHandler = (url) => {
        if (url && url !== 'no_file') {
            console.log('[URL__]', url);
            let _url = url.replace('https://hdpbxrecordings.s3.amazonaws.com/', '');
            _url = _url.replace(/[?]+.*/, '');
            const urlSplitted = _url.split('/');
            const date = urlSplitted.slice(0, 3).join('-');
            const fileName = urlSplitted.slice(3).join('');
            dispatch(isLoadingCallTranscriptAction(true));
            dispatch(fetchCallTranscriptAction(date, fileName));
        }
        setOpenModalCall(url);
    };

    const callBoxHandler = (call, index) => {
        setSummaryIndex(index)
        setCurrentPage(1)
        pullCallSummary(call, index)
    }

    const paginationHandler = async (e, value) => {
        await setCurrentPage(value)
        pullCallSummary(callList, summaryIndex, value)
    }

    return (
        <View
            recentsCallListState={callList}
            expanded={expanded}
            handleChange={handleChange}
            extState={extState}
            boxCallArray={boxCallArray}
            openModalCall={openModalCall}
            setOpenModalCall={openModalHandler}
            streamCallRecordhandler={streamCallRecordhandler}
            callRecordFiles={callRecordFiles}
            isLoadingCallTranscript={isLoadingCallTranscript}
            callTranscript={callTranscript}
            callBoxHandler={callBoxHandler}
            callBoxSummaryIndex={summaryIndex}
            nPages={nPage}
            page={currentPage}
            paginationHandler={paginationHandler}
            isLoading={callList}
            dateFilterComp={
                <DateFilter
                    handleOnSearch={handleOnSearch}
                    selectedStartDate={selectedStartDate}
                    setSelectedStartDate={setSelectedStartDate}
                    selectedEndDate={selectedEndDate}
                    setSelectedEndDate={setSelectedEndDate}
                    loading={isFilterRequested}
                    border={true}
                />
            }
        />
    );
};

export default Index;
