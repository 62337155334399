import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            marginTop: theme.spacing(2),
            textAlign: 'center',
        },
    },
    center: {
        display: 'flex',
        justifyContent: 'center',
    },
    right: {
        display: 'flex',
        justifyContent: 'flex-end !important',

    }
}));

export const BasicPagination = (props) => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Pagination classes={{ root: (props.position === 'right' && classes.right) || classes.center }} {...props} color='primary' />
        </div>
    );
};
