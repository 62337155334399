/**utilities's actions type */
export const IS_LOADING_BACKDROP = 'IS_LOADING_BACKDROP';
export const IS_LOADING_VIEW = 'IS_LOADING_VIEW';
export const SNACKBAR = 'SNACKBAR';
export const COLORS = 'COLORS';
export const SEARCH_FILTER_REQUEST = 'SEARCH_FILTER_REQUEST';

/**-------------------- */
/**SignIn's action type */
export const SIGN_IN_START = 'SIGN_IN_START';
export const SIGN_IN_COMPLETE = 'SIGN_IN_COMPLETE';
export const SIGNOUT_START = 'SIGNOUT_START';
export const SIGNOUT_COMPLETE = 'SIGNOUT_COMPLETE';


/**-------------------- */
/**forgot password action type */
export const FORGOT_PASSWORD_START = 'FORGOT_PASSWORD_START';
export const FORGOT_PASSWORD_COMPLETE = 'FORGOT_PASSWORD_COMPLETE';
export const FORGOT_PASSWORD_FAIL = 'FORGOT_PASSWORD_FAIL';
export const REQUEST_PASSWORD_TOKEN = 'REQUEST_PASSWORD_TOKEN';
export const SAVE_NEW_PASSWORD = 'SAVE_NEW_PASSWORD';


/**------------------ */
/** call's Action type */
export const RECENTS_CALLS_LIST_START = 'RECENTS_CALLS_LIST_START';
export const RECENTS_CALLS_LIST_COMPLETE = 'RECENTS_CALLS_LIST_COMPLETE';
export const PIE_DATA_CALLS_LIST_START = 'PIE_DATA_CALLS_LIST_START';
export const PIE_DATA_CALLS_LIST_COMPLETE = 'PIE_DATA_CALLS_LIST_COMPLETE';
export const LINE_DATA_CALLS_LIST_START = 'LINE_DATA_CALLS_LIST_START';
export const LINE_DATA_CALLS_LIST_COMPLETE = 'LINE_DATA_CALLS_LIST_COMPLETE';
export const LINE_DATA_CALLS_LIST_MONTH_START = 'LINE_DATA_CALLS_LIST_MONTH_START';
export const RECENTS_CALL_LIST_FILTER = 'RECENTS_CALL_LIST_FILTER';
export const FETCH_CALL_RECORD_START = 'FETCH_CALL_RECORD_START';
export const FETCH_CALL_RECORD_COMPLETE = 'FETCH_CALL_RECORD_COMPLETE';
export const FETCH_CALL_TRANSCRIPT_START = 'FETCH_CALL_TRANSCRIPT_START';
export const FETCH_CALL_TRANSCRIPT_COMPLETE = 'FETCH_CALL_TRANSCRIPT_COMPLETE';
export const IS_LOADING_CALL_TRANSCRIPT = 'IS_LOADING_CALL_TRANSCRIPT';

/**** */
/**User's action Types */
export const USER_LIST_START = 'USER_LIST_START';
export const USER_LIST_COMPLETE = 'USER_LIST_COMPLETE';
export const USER_DATA_START = 'GET_USER_DATA';
export const USER_DATA_COMPLETE = 'USER_DATA_COMPLETE';
/** */

/** ORGANIZATION KEYWORDS */
export const ACCESS_TOKEN = '-access_token-X';
export const REFRESH_TOKEN = '-refresh_token-X';
export const ACCESS_NUMBER = '-access_number-X';
export const ORGANIZATION_COLOR = '-organization-color-X';

/** Misscall */
export const GET_ALL_MISSCALL_START = 'GET_ALL_MISSCALL_START';
export const GET_ALL_MISSCALL_COMPLETE = 'GET_ALL_MISSCALL_COMPLETE';
export const GET_ROICHANNEL_START = 'GET_ROICHANNEL_START';
export const GET_ROICHANNEL_COMPLETE = 'GET_ROICHANNEL_COMPLETE';

/** Call Tracking */

export const GET_CALL_PROFILES_START = 'GET_CALL_PROFILES_START';
export const GET_CALL_PROFILES_COMPLETE = 'GET_CALL_PROFILES_COMPLETE';
export const GET_CALL_LOG = 'GET_CALL_LOG';
export const COLLAPSE_LOADING = 'COLLAPSE_LOADING';
