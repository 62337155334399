import { FORGOT_PASSWORD_START, FORGOT_PASSWORD_COMPLETE,FORGOT_PASSWORD_FAIL } from "../../consts/actionTypes";

const initialState = {
    isLoading: false,
    isTokenSend: false,
    error: null,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case FORGOT_PASSWORD_START:
            return {
                ...state,
                isLoading: true,
                error: null,
            };
        case FORGOT_PASSWORD_FAIL:
            return {
                ...state,
                isLoading: false,
                isTokenSend: false,
                error: action.payload?.error,
            };
        case FORGOT_PASSWORD_COMPLETE:
            return {
                ...state,
                isLoading: false,
                isTokenSend: true,
                error: null,
            };
        default:
            return { ...state };
    }
};

export default reducer;