import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { tokenSelector } from '../../Redux/selectors';
import PropTypes from 'prop-types';

const Index = (props) => {
    const { layout: Layout, component: Component, ...rest } = props;
    const tokenState = useSelector((state) => tokenSelector(state));

    return (
        <Route
            {...rest}
            render={({ location }) =>
                tokenState ? (
                    <Layout>
                        <Component />
                    </Layout>
                ) : (
                    <Redirect
                        to={{
                            pathname: '/',
                            state: { from: location },
                        }}
                    />
                )
            }
        />
    );
};

Index.propTypes = {
    component: PropTypes.any.isRequired,
    layout: PropTypes.any.isRequired,
    path: PropTypes.string,
};

export default Index;
