import React from 'react';
import ContentLoader from 'react-content-loader';

const Article = (props) => (
    <ContentLoader viewBox={props.viewBox} height={160} {...props}>
        <rect x='0' y='13' rx='4' ry='4' width={props.width} height='9' />
        <rect x='0' y='29' rx='4' ry='4' width='100' height='8' />
        <rect x='0' y='50' rx='4' ry='4' width={props.width} height='10' />
        <rect x='0' y='65' rx='4' ry='4' width={props.width} height='10' />
        <rect x='0' y='79' rx='4' ry='4' width='100' height='10' />
        <rect x='0' y='99' rx='5' ry='5' width={props.width} height='200' />
    </ContentLoader>
);

export default Article;
