import React from 'react';
import {
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Box,
} from '@material-ui/core/';
import dayjs from 'dayjs';
import { getOrganizationSpecificStyles } from '../../consts/theme';

const RowContent = ({ call, classes }) => {
  return (
    <Box style={{
      background: getOrganizationSpecificStyles().background,
      padding: 10,
      borderLeft: getOrganizationSpecificStyles().border,
      borderRadius: '5px 0px 0px 5px'
    }}>
      <Grid container spacing={2} alignItems='center'>
        <Grid md={3} className={classes.borderRight}>
          <List>
            <ListItem >
              <ListItemText
                primary={"AccountCode"}
                classes={{ primary: classes.bold }}
              />
              <ListItemSecondaryAction>
                <ListItemText
                  primary={call.accountcode || 'No records'}
                  classes={{ primary: classes.red }}
                />
              </ListItemSecondaryAction>
            </ListItem>
          </List>
          <List >
            <ListItem>
              <ListItemText
                primary={"AmaFlags"}
                classes={{ primary: classes.bold }}
              />
              <ListItemSecondaryAction>
                <ListItemText
                  primary={call.amaflags || 0}

                />
              </ListItemSecondaryAction>
            </ListItem>
          </List>
          <List >
            <ListItem>
              <ListItemText
                primary={"BillSec"}
                classes={{ primary: classes.bold }}
              />
              <ListItemSecondaryAction>
                <ListItemText
                  primary={call.billsec || 0}

                />
              </ListItemSecondaryAction>
            </ListItem>
          </List>
          <List >
            <ListItem>
              <ListItemText
                primary={"CallDuration"}
                classes={{ primary: classes.bold }}
              />
              <ListItemSecondaryAction>
                <ListItemText
                  primary={call.call_duration || 0}

                />
              </ListItemSecondaryAction>
            </ListItem>
          </List>
          <List >
            <ListItem>
              <ListItemText
                primary={"CallDate"}
                classes={{ primary: classes.bold }}
              />
              <ListItemSecondaryAction>
                <ListItemText
                  primary={dayjs(call.calldate).format('ddd, DD MM YYYY')}

                />
              </ListItemSecondaryAction>
            </ListItem>
          </List>
          <List >
            <ListItem>
              <ListItemText
                primary={"CallDate_date"}
                classes={{ primary: classes.bold }}
              />
              <ListItemSecondaryAction>
                <ListItemText
                  primary={call.calldate_date}

                />
              </ListItemSecondaryAction>
            </ListItem>
          </List>
        </Grid>

        <Grid md={3} className={classes.borderRight}>
          <List >
            <ListItem>
              <ListItemText
                primary={"Channel"}
                classes={{ primary: classes.bold }}
              />
              <ListItemSecondaryAction className={classes.scrollbar}>
                <ListItemText
                  primary={call.channel || 'No records'}
                />
              </ListItemSecondaryAction>
            </ListItem>
          </List>
          <List >
            <ListItem>
              <ListItemText
                primary={"Clid"}
                classes={{ primary: classes.bold }}
              />
              <ListItemSecondaryAction className={classes.scrollbar}>
                <ListItemText
                  primary={call.clid || 0}

                />
              </ListItemSecondaryAction>
            </ListItem>
          </List>
          <List >
            <ListItem>
              <ListItemText
                primary={"RecordingFile"}
                classes={{ primary: classes.bold }}
              />
              <ListItemSecondaryAction>
                <ListItemText
                  primary={'call.recordingfile'}

                />
              </ListItemSecondaryAction>
            </ListItem>
          </List>
          <List >
            <ListItem>
              <ListItemText
                primary={"Outbound_cnam"}
                classes={{ primary: classes.bold }}
              />
              <ListItemSecondaryAction>
                <ListItemText
                  primary={call.outbound_cnam || 0}

                />
              </ListItemSecondaryAction>
            </ListItem>
          </List>
          <List >
            <ListItem>
              <ListItemText
                primary={"Dst"}
                classes={{ primary: classes.bold }}
              />
              <ListItemSecondaryAction>
                <ListItemText
                  primary={call.dst}

                />
              </ListItemSecondaryAction>
            </ListItem>
          </List>
          <List >
            <ListItem>
              <ListItemText
                primary={"Dst_cname"}
                classes={{ primary: classes.bold }}
              />
              <ListItemSecondaryAction>
                <ListItemText
                  primary={call.dst_cnam}

                />
              </ListItemSecondaryAction>
            </ListItem>
          </List>
        </Grid>

        <Grid md={3} className={classes.borderRight}>
          <List >
            <ListItem>
              <ListItemText
                primary={"Lastapp"}
                classes={{ primary: classes.bold }}
              />
              <ListItemSecondaryAction>
                <ListItemText
                  primary={call.lastapp}
                />
              </ListItemSecondaryAction>
            </ListItem>
          </List>
          <List >
            <ListItem>
              <ListItemText
                primary={"Lastdata"}
                classes={{ primary: classes.bold }}
              />
              <ListItemSecondaryAction className={classes.scrollbar}>
                <ListItemText
                  primary={call.lastdata || 0}

                />
              </ListItemSecondaryAction>
            </ListItem>
          </List>
          <List >
            <ListItem>
              <ListItemText
                primary={"Dcontext"}
                classes={{ primary: classes.bold }}
              />
              <ListItemSecondaryAction>
                <ListItemText
                  primary={call.dcontext}

                />
              </ListItemSecondaryAction>
            </ListItem>
          </List>
          <List >
            <ListItem>
              <ListItemText
                primary={"Did"}
                classes={{ primary: classes.bold }}
              />
              <ListItemSecondaryAction>
                <ListItemText
                  primary={call.did}

                />
              </ListItemSecondaryAction>
            </ListItem>
          </List>
          <List >
            <ListItem>
              <ListItemText
                primary={"Uniqueid"}
                classes={{ primary: classes.bold }}
              />
              <ListItemSecondaryAction>
                <ListItemText
                  primary={call.uniqueid}

                />
              </ListItemSecondaryAction>
            </ListItem>
          </List>
          <List >
            <ListItem>
              <ListItemText
                primary={"Userfield"}
                classes={{ primary: classes.bold }}
              />
              <ListItemSecondaryAction>
                <ListItemText
                  primary={call.userfield}

                />
              </ListItemSecondaryAction>
            </ListItem>
          </List>
        </Grid>

        <Grid md={3}>
          <List >
            <ListItem>
              <ListItemText
                primary={"Calldate_time"}
                classes={{ primary: classes.bold }}
              />
              <ListItemSecondaryAction>
                <ListItemText
                  primary={call.calldate_time}
                />
              </ListItemSecondaryAction>
            </ListItem>
          </List>
          <List >
            <ListItem>
              <ListItemText
                primary={"Disposition"}
                classes={{ primary: classes.bold }}
              />
              <ListItemSecondaryAction>
                <ListItemText
                  primary={call.disposition || 0}

                />
              </ListItemSecondaryAction>
            </ListItem>
          </List>
          <List >
            <ListItem>
              <ListItemText
                primary={"Cnam"}
                classes={{ primary: classes.bold }}
              />
              <ListItemSecondaryAction>
                <ListItemText
                  primary={call.cnam || "--"}

                />
              </ListItemSecondaryAction>
            </ListItem>
          </List>
          <List >
            <ListItem>
              <ListItemText
                primary={"Cnum"}
                classes={{ primary: classes.bold }}
              />
              <ListItemSecondaryAction>
                <ListItemText
                  primary={call.cnum || "--"}

                />
              </ListItemSecondaryAction>
            </ListItem>
          </List>
          <List >
            <ListItem>
              <ListItemText
                primary={"Src"}
                classes={{ primary: classes.bold }}
              />
              <ListItemSecondaryAction>
                <ListItemText
                  primary={call.src}

                />
              </ListItemSecondaryAction>
            </ListItem>
          </List>
          <List >
            <ListItem>
              <ListItemText
                primary={"Outbound_cnum"}
                classes={{ primary: classes.bold }}
              />
              <ListItemSecondaryAction>
                <ListItemText
                  primary={call.outbound_cnum}

                />
              </ListItemSecondaryAction>
            </ListItem>
          </List>
        </Grid>
      </Grid>
    </Box>
  );
};

export default RowContent;
