import React from 'react';
import { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import dayjs from 'dayjs';

import { roiCallAction } from '../../Redux/actions/miscallAction';
import { roicallSelector, doesFilterRequestSelector } from '../../Redux/selectors';
import { View } from './view';

export const ROIChannel = () => {
    const dispatch = useDispatch();
    const isFilterRequested = useSelector((state) => doesFilterRequestSelector(state));
    const didNumberElement = useRef(0);
    const roicalls = useSelector((state) => roicallSelector(state));
    const [selectedStartDate, setSelectedStartDate] = useState(dayjs().startOf('month'));
    const [selectedEndDate, setSelectedEndDate] = useState(new Date());

    useEffect(() => {
        const today = dayjs().format('YYYY-MM-DD');
        const firstDate = dayjs().startOf('month').format('YYYY-MM-DD');
        const unsubscribe = dispatch(roiCallAction(firstDate, today, 0));
        return () => unsubscribe;
    }, []);

    const handleOnSearch = () => {
        dispatch(
            roiCallAction(
                dayjs(selectedStartDate).format('YYYY-MM-DD'),
                dayjs(selectedEndDate).format('YYYY-MM-DD'),
                didNumberElement.current.value || 0
            )
        );
    };

    const setStartTimeHandler = (d) => setSelectedStartDate(d);
    const setEndTimeHandler = (d) => setSelectedEndDate(d);

    return (
        <View
            handleOnSearch={handleOnSearch}
            selectedStartDate={selectedStartDate}
            setSelectedStartDate={setStartTimeHandler}
            selectedEndDate={selectedEndDate}
            setSelectedEndDate={setEndTimeHandler}
            roicalls={roicalls}
            loading={isFilterRequested}
        />
    );
};
