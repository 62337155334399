import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

const useStyles = makeStyles({
    root: {
        margin: 10,
        flex: 1,
        display: 'flex',
    },
    content: {
        flex: 1,
        paddingBottom: '16px!important',
    },
    contentPadding: {
        padding: '0px',
    },
    border: {
        border: '1px solid #d9d9d9'
    }
});

const SimpleCard = (props) => {
    const { children, paddingZero } = props;
    const classes = useStyles();

    return (
        <Card className={`${classes.root} ${props.border && classes.border}`} elevation={props.elevation}>
          <CardContent className={`${classes.content} ${paddingZero && classes.contentPadding}`}>{children}</CardContent>
        </Card>
    );
};

export default SimpleCard;
