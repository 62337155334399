/** @format */

import { call, put, select, takeLatest } from 'redux-saga/effects';

import {
    GET_CALL_PROFILES_COMPLETE,
    GET_CALL_PROFILES_START,
    GET_CALL_LOG,
} from '../../consts/actionTypes';
import { tokenSelector, organizationSelector } from '../selectors';
import { apiCall } from '../../consts/funtions';
import { CALL_TRACKING } from '../../consts/url';
import tz from '../../consts/timezone';

import { errorHandler } from '../../consts/errorHandler';
/**
 * Retrieves call tracking data
 *
 * @param {Object} params              - The object containing params as key-value pair
 * @param {String} params.callInfo     - CallInfo could be either 'callprofile' or 'calllog
 *                                     - callprofile will get  all the customer
 *                                     - calllog will get the each customer call logs
 * @param {String} params.status       - status could be I/ O/ M/ ALL
 * @param {String} params.extension    - did number
 * @param {Date}   params.date_from    - date_from
 * @param {Date}   params.date_to      - date_to
 * @param {Number} params.page         - page number
 * @param {Number} params.size         - size, offset
 * @param {Boolean} params.isSearched  - if true, will just show the searched items
 *
 * @returns
 */
function* retrieveCallTracking(params) {
    try {
        const { callInfo, status, extension, date_from, date_to, page, size, isSearched } = params;
        const token = yield select(tokenSelector);
        const organization = yield select(organizationSelector);
        // sdkphone/callInfo/status/3052563284/0/0/Asia%252FKarachi/1/5
        const result = yield call(
            apiCall,
            `${CALL_TRACKING}/${organization}/${callInfo}/${status}/${extension}/${date_from}/${date_to}/${tz}/${page}/${size}`,
            null,
            token,
            'GET'
        );

        if (extension !== '0' && !isSearched) {
            yield put({ type: GET_CALL_LOG, calls: result?.data?.[extension], extension });
            return;
        }

        yield put({ type: GET_CALL_PROFILES_COMPLETE, calls: result?.data?.result });
    } catch (error) {
        yield put(errorHandler(error));
    }
}

export default function* watch() {
    yield takeLatest(GET_CALL_PROFILES_START, retrieveCallTracking);
}
