import { FORGOT_PASSWORD_START,FORGOT_PASSWORD_COMPLETE,FORGOT_PASSWORD_FAIL,REQUEST_PASSWORD_TOKEN,SAVE_NEW_PASSWORD } from "../../consts/actionTypes";


export const forgotPasswordStartAction = (payload) => ({
    type: FORGOT_PASSWORD_START,
    payload,
});

export const forgotPasswordCompleteAction = (payload) => ({
    type: FORGOT_PASSWORD_COMPLETE,
    payload,
});

export const forgotPasswordFailAction = (payload) => ({
    type: FORGOT_PASSWORD_FAIL,
    payload,
});

export const requestPasswordTokenAction = (payload) => ({
     type: REQUEST_PASSWORD_TOKEN,
     payload,
});

export const saveNewPasswordAction = (payload) => ({
    type: SAVE_NEW_PASSWORD,
    payload,
});