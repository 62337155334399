import { put, call, takeLatest, select } from 'redux-saga/effects';
import {
    USER_LIST_START,
    USER_LIST_COMPLETE,
    USER_DATA_START,
    USER_DATA_COMPLETE,
} from '../../consts/actionTypes';
import { apiCall } from '../../consts/funtions';
import { isLoadingViewAction } from '../actions/utilitiesAction';
import { USER } from '../../consts/url';
import { tokenSelector } from '../selectors';
import { errorHandler } from '../../consts/errorHandler';

function* userList() {
    try {
        yield put(isLoadingViewAction(true));
        const tokenState = yield select(tokenSelector);
        const results = yield call(apiCall, USER, null, tokenState, 'GET');
        yield put({ type: USER_LIST_COMPLETE, payload: results.data });
    } catch (error) {
        yield put(errorHandler(error));
    }
    yield put(isLoadingViewAction(false));
}

function* getUserData() {
    try {
        yield put(isLoadingViewAction(true));
        const tokenState = yield select(tokenSelector);
        const result = yield call(apiCall, 'protected', null, tokenState, 'GET');
        yield put({ type: USER_DATA_COMPLETE, payload: result.data });
    } catch (error) {
        yield put(errorHandler(error));
    }
    yield put(isLoadingViewAction(false));
}

export default function* user() {
    yield takeLatest(USER_LIST_START, userList);
    yield takeLatest(USER_DATA_START, getUserData);
}
