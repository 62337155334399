import React, { forwardRef } from 'react';
import { makeStyles, Typography, Box, useTheme, withStyles } from '@material-ui/core/';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';

import { DateFilter, Pagination } from '../../components';
import { getOrganizationSpecificStyles } from '../../consts/theme';
import { InboxOutlined } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    container: {
        flex: 1,
        flexDirection: 'column',
        overflow: 'auto',
        padding: theme.spacing(2),
    },

    root: {
        width: '98.4%',
        margin: '10px',
    },
}));

const useRowStyles = makeStyles({
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
    scrollX: {
        overflowX: 'auto',
        maxWidth: '100%',
    },

    bold: {
        fontWeight: '900',
    }
});

const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: "black",
      color: "white",
    }
  }))(TableCell);

function Row(props) {
    const { call } = props;
    const [open, setOpen] = React.useState(false);
    const classes = useRowStyles();
    const theme = useTheme();

    return (
        <React.Fragment>
            <TableRow className={classes.root}>
                <TableCell>
                    <IconButton aria-label='expand row' size='small' onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell align='left'>{call?.did_number}</TableCell>
                <TableCell align='left'>{call?.description}</TableCell>
                <TableCell align='left'>{dayjs().format('MM/DD/YYYY H:M:s')}</TableCell>
                <TableCell align='left' style={{ color: theme.palette.red }}>
                    {call?.total_missed_calls}
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0, paddingLeft: 0 }} colSpan={6}>
                    <Collapse in={open} timeout='auto' unmountOnExit>
                        <Box className={classes.scrollX} style={{
                            background: getOrganizationSpecificStyles().background,
                            padding: 10,
                            borderLeft: getOrganizationSpecificStyles().border,
                            borderRadius: '5px 0px 0px 5px'
                        }}>
                            <Typography variant='h6' gutterBottom component='div'>
                                History
                            </Typography>
                            <Table size='small' aria-label='purchases'>
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.bold}>DID Number</TableCell>
                                        <TableCell className={classes.bold}>Source</TableCell>
                                        <TableCell className={classes.bold}>Destination</TableCell>
                                        <TableCell className={classes.bold}>Duration </TableCell>
                                        <TableCell className={classes.bold}>Date </TableCell>
                                        <TableCell className={classes.bold}>Time </TableCell>
                                        <TableCell className={classes.bold}>Caller ID </TableCell>
                                        <TableCell className={classes.bold}>Caller Name</TableCell>
                                        <TableCell className={classes.bold}>Caller Number</TableCell>
                                        <TableCell className={classes.bold}>Disposition</TableCell>
                                        <TableCell className={classes.bold}>Destination Caller Name </TableCell>
                                        <TableCell className={classes.bold}>Last Step</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {call?.calls?.map((details) => (
                                        <TableRow key={details.calldate_time + Math.random() * 999}>
                                            <TableCell
                                                component='th'
                                                scope='row'
                                                style={{ color: theme.palette.red }}
                                            >
                                                {details?.did}
                                            </TableCell>
                                            <TableCell>{details.src}</TableCell>
                                            <TableCell>{details.dst}</TableCell>
                                            <TableCell>{details.call_duration}</TableCell>
                                            <TableCell>{details.calldate_date}</TableCell>
                                            <TableCell>{details.calldate_time}</TableCell>

                                            <TableCell>{details.clid}</TableCell>
                                            <TableCell>{details.cnam}</TableCell>
                                            <TableCell>{details.cnum}</TableCell>
                                            <TableCell>{details.disposition}</TableCell>
                                            <TableCell>{details.dst_cnam}</TableCell>
                                            <TableCell>{details.lastapp}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

export const View = forwardRef((props, ref) => {
    const {
        handleOnSearch,
        selectedStartDate,
        setSelectedStartDate,
        selectedEndDate,
        setSelectedEndDate,
        miscalls,
        loading,
    } = props;

    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const theme = useTheme();

    const startIndex = page * rowsPerPage;
    const endIndex = page * rowsPerPage + rowsPerPage;
    const missCallsPageChangingData = miscalls.slice(startIndex, endIndex);

    const handlePaginationChange = (event, newPage) => {
        if(event.target.value === 'rowsPerPage') {
            setRowsPerPage(event.target.value);
            setPage(0);
        } else {
            setPage(newPage);
        }
    }

    return (
        <div className={classes.container}>
            <DateFilter
                handleOnSearch={handleOnSearch}
                selectedStartDate={selectedStartDate}
                setSelectedStartDate={setSelectedStartDate}
                selectedEndDate={selectedEndDate}
                setSelectedEndDate={setSelectedEndDate}
                extraIF={'DID Number'}
                ref={ref}
                format='MM/dd/yyyy'
                loading={loading}
            />

            <Paper>
                <TableContainer style={{borderRadius: "4px"}}>
                    <Table aria-label='collapsible table'>
                        <TableHead>
                            <TableRow>
                                <StyledTableCell align='left'>Details</StyledTableCell>
                                <StyledTableCell align='left'>DID Number</StyledTableCell>
                                <StyledTableCell align='left'>Description</StyledTableCell>
                                <StyledTableCell align='left'>Last Update</StyledTableCell>
                                <StyledTableCell align='left'>Total missed calls</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {missCallsPageChangingData?.map((call) => (
                                <Row key={Math.random() * 999999} call={call} />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                {!miscalls?.length && (
                    <Box className={classes.noDataContainer} sx={{ display: "grid", justifyItems: "center", alignItems: "center", marginTop: "1rem", paddingBottom: "2rem" }}>
                        <InboxOutlined className={classes.icon} />
                        <Typography
                            variant="h5"
                            component="h2"
                            align="center"
                            className={classes.text}
                        >
                            No data found
                        </Typography>
                        <Typography
                            variant="body1"
                            color="textSecondary"
                            align="center"
                        >
                            There are no items to display at the moment
                        </Typography>
                    </Box>
                )}
            </Paper>
            <Pagination
                count={Math.ceil(miscalls?.length / rowsPerPage) || 1}
                page={page + 1}
                onChange={(event, newPage) => handlePaginationChange(event, newPage - 1)}
                variant="outlined"
                position="right"
                color={"#fd681"}
            />
        </div>
    );
});

View.propTypes = {
    handleOnSearch: PropTypes.func.isRequired,
    selectedStartDate: PropTypes.object.isRequired,
    setSelectedStartDate: PropTypes.func.isRequired,
    selectedEndDate: PropTypes.object.isRequired,
    setSelectedEndDate: PropTypes.func.isRequired,
    miscalls: PropTypes.array.isRequired,
    loading: PropTypes.bool,
};
