import { GET_ALL_MISSCALL_COMPLETE, GET_ROICHANNEL_COMPLETE } from '../../consts/actionTypes';
import dayjs from 'dayjs';

const initailState = {
    miscalls: [],
    roiCalls: [],
};

export default (state = initailState, action) => {
    switch (action.type) {
        case GET_ALL_MISSCALL_COMPLETE:
            return {
                ...state,
                miscalls: action.payload,
            };
        case GET_ROICHANNEL_COMPLETE:
            return {
                ...state,
                roiCalls: action.payload,
            };

        default:
            return state;
    }
};
