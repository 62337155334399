import React, { useEffect } from 'react';
import {
    makeStyles,
    Typography,
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Button,
    withStyles,
} from '@material-ui/core/';
import { ActionCard, Pagination, SimpleCard, SkeletonList } from '../../components';
import PropTypes from 'prop-types';
import { BoxCall, ModalCall, RecentCallRow, LineChart, PieChart } from './components';
import { InboxOutlined } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        overflow: 'auto',
        padding: 20,
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
    },
    stats: {
        flex: 1,
        alignSelf: 'center',
        color: '#fc5621',
        fontWeight: '500',
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    fontSize: {
        ['&.table, &.table tr, & td, & th, & ul, & li, & li span']: {
            fontSize: '14px !important'
        }
    }
}));

const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: "black",
      color: "white",
    }
  }))(TableCell);

const View = (props) => {
    const classes = useStyles();
    const {
        recentsCallListState,
        extState,
        boxCallArray,
        openModalCall,
        setOpenModalCall,
        dateFilterComp,
        isLoadingCallTranscript,
        callTranscript,
        callBoxHandler,
        callBoxSummaryIndex,
        nPages,
        page,
        paginationHandler
    } = props;

    console.log('[recentsCallListState X')

    const callsTitleToShow = [0, 1, 2, 3]; 

    return (
        <div className={classes.container}>
            {/* Date Filter topbar */}
            {dateFilterComp}

            <Box className={classes.row}>
                {boxCallArray
                    .filter((box, index) => callsTitleToShow.includes(index))
                    .map((box, index) => {
                        return (
                          <BoxCall
                            key={index}
                            active={
                              callBoxSummaryIndex === index &&
                              ((index === 0 && 'red') ||
                                (index === 1 && 'green') ||
                                (index === 2 && 'blue') ||
                                (index === 3 && 'pink'))
                            }
                            titleBox={box.title}
                            colorValue={box.colorValue}
                            valueCant={box.value}
                            valueprogressBar={box.progressbar}
                            onClick={callBoxHandler.bind(null, box, index)}
                          />
                        );
                    })}
            </Box>

            <Box style={{textAlign: 'left', margin: '25px 8px 0px'}}>
                <Typography
                    variant='h6'
                    className={classes.stats}
                    style={{ color: 'black', marginBottom: 15}}
                    >
                    {["All CALLS", "INBOUND", "OUTBOUND", "MISSED CALL"][callBoxSummaryIndex]}
                </Typography>
            </Box>

            <Box className={classes.row} style={{flexDirection: 'column'}}>
                <SimpleCard elevation={0} border={true} paddingZero={true}>
                    <TableContainer>
                        <Table className={classes.fontSize}>
                            {!recentsCallListState?.result || recentsCallListState.result.length === 0 ? (
                                <Box sx={{ display: "grid", justifyItems: "center", alignItems: "center", marginTop: "1rem", paddingBottom: "2rem" }}>
                                    <InboxOutlined />
                                    <Typography
                                        variant="h5"
                                        component="h2"
                                        align="center"
                                        className={classes.text}
                                    >
                                        No data found
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                        color="textSecondary"
                                        align="center"
                                    >
                                        There are no items to display at the moment
                                    </Typography>
                                </Box>
                            ) : (
                                <>
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell>Call Details</StyledTableCell>
                                            <StyledTableCell>From (Ext)</StyledTableCell>
                                            <StyledTableCell>To (Ext)</StyledTableCell>
                                            <StyledTableCell>Status</StyledTableCell>
                                            <StyledTableCell>Date and Time</StyledTableCell>
                                            <StyledTableCell>Duration</StyledTableCell>
                                            <StyledTableCell>Recording Call</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {recentsCallListState.result.map((call, index) => (
                                            <RecentCallRow
                                                key={index}
                                                row={call}
                                                extState={extState}
                                                openModalCall={setOpenModalCall}
                                            />
                                        ))}
                                    </TableBody>
                                </>
                            )}
                        </Table>
                    </TableContainer>
                </SimpleCard>

                <Pagination count={nPages || 0} page={page} onChange={paginationHandler} variant="outlined" position="right" color={"#fd6817"} />
            </Box>      
            <ModalCall
                openModal={openModalCall}
                handleClose={setOpenModalCall}
                isLoadingCallTranscript={isLoadingCallTranscript}
                callTranscript={callTranscript}
            />
        </div>
    );
};
View.propTypes = {
    recentsCallListState: PropTypes.object.isRequired,
    extState: PropTypes.string.isRequired,
    boxCallArray: PropTypes.array.isRequired,
    openModalCall: PropTypes.any.isRequired,
    setOpenModalCall: PropTypes.func.isRequired,
    isLoadingCallTranscript: PropTypes.bool.isRequired,
    callTranscript: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]).isRequired,
};
View.defaultProps = {};
export default View;
