import axios from 'axios';
import { getLocalStorage, setLocalStorage, rmLocalStorage } from '@lifeeric/react-localstroage';

import { URL_API } from './url';
import { organization } from './organization';
import { ACCESS_TOKEN, REFRESH_TOKEN } from './actionTypes';

//Add a response interceptor
axios.interceptors.response.use(
    (response) => {
        // token failed, status_code 401
        if (
            response.data.status_code === 401 &&
            response.data.description !== 'username or password incorrect.'
        ) {
            return refrestTokenAndReattempRequest(response);
        }
        return response;
    },
    function (error) {
        return refrestTokenAndReattempRequest(error);
    }
);

const refrestTokenAndReattempRequest = (error) => {
    const refreshToken = getLocalStorage(organization().name + REFRESH_TOKEN);
    const originalRequest = error.config;

    if (error.data.status_code === 401 && originalRequest.url === `${URL_API}refresh_token`) {
        rmLocalStorage(organization().name + REFRESH_TOKEN);
        rmLocalStorage(organization().name + ACCESS_TOKEN);
        window.location.href = '/';
        return Promise.reject(error);
    }

    if (error.data.status_code === 401 && !originalRequest._retry) {
        originalRequest._retry = true;

        return axios
            .post(`${URL_API}refresh_token`, null, {
                headers: { Authorization: `JWT ${refreshToken}` },
            })
            .then((res) => {
                if (res.status === 200) {
                    setLocalStorage(organization().name + ACCESS_TOKEN, res.data.access_token);
                    // axios.defaults.headers.common['Authorization'] = 'JWT ' + res.data.access_token;
                    originalRequest.headers['Authorization'] = 'JWT ' + res.data.access_token;
                    return axios(originalRequest);
                }
            });
    }
    return Promise.reject(error);
};

export const authCall = (url, data, headers, method) =>
    axios({
        method,
        url: URL_API + url,
        data,
        headers,
    });

/**
 *
 * @param {String} url    - url for the endpoing
 * @param {Object} data   - body of the request
 * @param {String} token  - jwt token
 * @param {String} method - method could be GET/POST/PUT/PATCH
 *
 * @returns {object}  response from the API call
 */

export const apiCall = (url, data, token, method) =>
    axios({
        method,
        url: URL_API + url,
        data,
        headers: {
            Authorization: `JWT ${token}`,
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
        },
    });

export const formatNumber = (number) => {
    if (Number(number) < 10) {
        return `0${number}`;
    } else {
        return `${number}`;
    }
};
