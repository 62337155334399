import { SIGN_IN_COMPLETE, SIGNOUT_COMPLETE } from '../../consts/actionTypes';
const initialState = {
    access_token: null,
    access_number: null,
    organization: null,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SIGN_IN_COMPLETE:
            return {
                ...state,
                ...action.payload.data,
            };
        case SIGNOUT_COMPLETE:
            return {
                ...state,
                access_token: null,
                access_number: null,
                organization: null,
            };

        default:
            return { ...state };
    }
};
export default reducer;
