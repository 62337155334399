import { put, call, takeLatest } from 'redux-saga/effects';
import {
    SIGN_IN_START,
    SIGN_IN_COMPLETE,
    COLORS,
    LINE_DATA_CALLS_LIST_START,
    SIGNOUT_START,
    SIGNOUT_COMPLETE,
    ACCESS_TOKEN,
    ACCESS_NUMBER,
    REFRESH_TOKEN,
    ORGANIZATION_COLOR,
    // RECENTS_CALLS_LIST_START,
} from '../../consts/actionTypes';
import { setLocalStorage, rmLocalStorage } from '@lifeeric/react-localstroage';

import { authCall, apiCall } from '../../consts/funtions';
import { organization } from '../../consts/organization';
import { isLoadingBackdropAction, snackBarAction } from '../actions/utilitiesAction';
import { AUTH, COLOR_BY_ORGANITATION /*RECENTS*/ } from '../../consts/url';
import { errorHandler } from '../../consts/errorHandler';

function* apiCallAuth({ payload }) {
    try {
        const results = yield call(authCall, AUTH, payload, null, 'POST');
        const token = results.data.access_token;
        const refreshToken = results.data.refresh_token;

        if (!results.data.access_number) {
            yield put(
                snackBarAction({
                    open: true,
                    msg: 'Please contact the Admin for the Extension',
                    type: 'warning',
                })
            );
            yield put(isLoadingBackdropAction(false));
            return;
        }

        switch (results.data.status_code) {
            case 200:
                yield setLocalStorage(organization().name + ACCESS_TOKEN, token);
                yield setLocalStorage(organization().name + REFRESH_TOKEN, refreshToken);
                yield setLocalStorage(
                    organization().name + ACCESS_NUMBER,
                    results.data.access_number
                );
                yield put({ type: SIGN_IN_COMPLETE, payload: results });
                const colors = yield call(
                    apiCall,
                    `${COLOR_BY_ORGANITATION}/${payload.organization}`,
                    null,
                    results.data.access_token,
                    'GET'
                );
                yield setLocalStorage(
                    organization().name + ORGANIZATION_COLOR,
                    JSON.stringify(colors)
                );
                yield put({ type: COLORS, payload: colors });
                yield put({ type: LINE_DATA_CALLS_LIST_START, payload: '2021' });
                // yield put({ type: RECENTS_CALLS_LIST_START, payload: results.data.access_number })
                break;
            case 401:
                yield put(errorHandler(results));
                yield put(isLoadingBackdropAction(false));
                break;
            default:
                console.log('results', results);
                break;
        }
    } catch (error) {
        console.log('apiCallAuth signiSaga', error);
        yield put(errorHandler(error));
    }
    yield put(isLoadingBackdropAction(false));
}

function* signOutUser() {
    yield rmLocalStorage(organization().name + ACCESS_TOKEN);
    yield rmLocalStorage(organization().name + REFRESH_TOKEN);
    yield rmLocalStorage(organization().name + ACCESS_NUMBER);
    yield rmLocalStorage(organization().name + ORGANIZATION_COLOR);

    yield put({ type: SIGNOUT_COMPLETE });
}

export default function* signIn() {
    yield takeLatest(SIGN_IN_START, apiCallAuth);
    yield takeLatest(SIGNOUT_START, signOutUser);
}
