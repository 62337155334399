import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

const ProgressBar = (props) => {
    const { barColor, value } = props;

    const LinearProgressStyled = withStyles(() => ({
        root: {
            height: 7,
            borderRadius: 5,
            marginTop: 10,
        },
        colorPrimary: {
            backgroundColor: '#f2f2f2',
        },
        bar: {
            borderRadius: 5,
            backgroundColor: barColor,
        },
    }))(LinearProgress);

    return <LinearProgressStyled variant='determinate' value={value} />;
};

export default ProgressBar;
