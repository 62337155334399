import {
    RECENTS_CALLS_LIST_COMPLETE,
    PIE_DATA_CALLS_LIST_COMPLETE,
    LINE_DATA_CALLS_LIST_COMPLETE,
    FETCH_CALL_RECORD_COMPLETE,
    FETCH_CALL_TRANSCRIPT_COMPLETE,
    IS_LOADING_CALL_TRANSCRIPT,
} from '../../consts/actionTypes';

const pieDataCallDefault = [
    {
        color: '#12b3ab',
        id: 'inbound',
        label: 'INBOUND',
        value: 0,
    },

    {
        color: '#7a4ff5',
        id: 'oubound',
        label: 'OUTBOUND',
        value: 0,
    },

    {
        color: '#bc01cc',
        id: 'internal',
        label: 'INTERNAL',
        value: 0,
    },
];

const boxCallsDefault = [
    {
        title: 'CALL HISTORY',
        colorValue: '#fc5621',
        value: 0,
        progressbar: '0',
        results: []
    },
    {
        title: 'INBOUND',
        colorValue: '#12b3ab',
        value: 0,
        progressbar: '0',
    },
    {
        title: 'OUTBOUND',
        colorValue: '#7a4ff5',
        value: 0,
        progressbar: '0',
    },
    {
        title: 'INTERNAL',
        colorValue: '#bc01cc',
        value: 0,
        progressbar: '0',
    },
];

const initialState = {
    recentsCallListByExt: {},
    pieDataCall: pieDataCallDefault,
    lineDataCall: [],
    boxCalls: boxCallsDefault,
    callRecordFiles: {},
    callTranscript: false,
    isLoadingCallTranscript: false,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case RECENTS_CALLS_LIST_COMPLETE:
            return {
                ...state,
                recentsCallListByExt: action.payload,
            };
        case PIE_DATA_CALLS_LIST_COMPLETE:
            const dataforType = action.payload;
            console.log('[DataForType]', dataforType)
            const totalCalls = dataforType.reduce((a, b) => {
                return a + Number(b.value);
            }, 0);
            const boxCallArray = dataforType.map((item, key) => {
                return {
                    title: item.label,
                    colorValue: item.color,
                    value: item.value,
                    progressbar: ((Number(item.value) * 100) / Number(totalCalls)).toFixed(2),
                    results: item?.results
                };
            });

            // If there no data found from the remote,
            // show the default data
            if (dataforType.length === 0) {
                console.log('yes its');
                return {
                    ...state,
                    boxCalls: boxCallsDefault,
                    pieDataCall: pieDataCallDefault,
                };
            } else
                return {
                    ...state,
                    pieDataCall: dataforType,
                    boxCalls: [
                        {
                            title: 'CALL HISTORY',
                            colorValue: '#fc5621',
                            value: totalCalls,
                            progressbar: "100",
                            results: [...(dataforType[0]?.results || []), ...(dataforType[1]?.results || [])]
                        },
                        ...boxCallArray,
                    ],
                };

        case LINE_DATA_CALLS_LIST_COMPLETE:
            return {
                ...state,
                lineDataCall: action.payload,
            };
        case FETCH_CALL_RECORD_COMPLETE:
            return {
                ...state,
                callRecordFiles: {
                    ...state.callRecordFiles,
                    [action.payload.filename]: action.payload.data,
                },
            };
        case FETCH_CALL_TRANSCRIPT_COMPLETE:
            return {
                ...state,
                callTranscript: action.payload,
                isLoadingCallTranscript: false,
            };
        case IS_LOADING_CALL_TRANSCRIPT:
            return {
                ...state,
                isLoadingCallTranscript: action.isLoading,
            };
        default:
            return { ...state };
    }
};
export default reducer;
