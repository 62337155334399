import {
    RECENTS_CALLS_LIST_START,
    PIE_DATA_CALLS_LIST_START,
    LINE_DATA_CALLS_LIST_START,
    LINE_DATA_CALLS_LIST_MONTH_START,
    RECENTS_CALL_LIST_FILTER,
    FETCH_CALL_RECORD_START,
    IS_LOADING_CALL_TRANSCRIPT,
    FETCH_CALL_TRANSCRIPT_START,
} from '../../consts/actionTypes';

export const recentsCallsListAction = (payload) => ({
    type: RECENTS_CALLS_LIST_START,
    payload,
});

export const pieDataListAction = (start, end) => ({
    type: PIE_DATA_CALLS_LIST_START,
    start,
    end,
});

export const lineDataListAction = (payload) => ({
    type: LINE_DATA_CALLS_LIST_START,
    payload,
});

export const lineDataListMonthAction = (year, month) => ({
    type: LINE_DATA_CALLS_LIST_MONTH_START,
    year,
    month,
});

export const recentsCallsFilterAction = (start, end, offset = 0) => ({
    type: RECENTS_CALL_LIST_FILTER,
    start,
    end,
    offset,
});

export const callRecordFileAction = (date, filename) => ({
    type: FETCH_CALL_RECORD_START,
    date,
    filename,
});

export const isLoadingCallTranscriptAction = (status) => ({
    type: IS_LOADING_CALL_TRANSCRIPT,
    isLoading: status,
});

export const fetchCallTranscriptAction = (date, filename) => ({
    type: FETCH_CALL_TRANSCRIPT_START,
    date,
    filename,
});
