import React, { forwardRef } from 'react';
import { makeStyles, Box, Button } from '@material-ui/core/';
import { SimpleCard } from '../../components';
import { DatePicker } from '@material-ui/pickers';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import LoopIcon from '@material-ui/icons/Loop';

const useStyles = makeStyles((theme) => ({
    row: {
        display: 'flex',
        flexDirection: 'row',

    },
    marginRight: { marginRight: theme.spacing(2) },
    icon: {
        animation: '$rotateIcon 1s infinite linear',
    },
    '@keyframes rotateIcon': {
        '0%': {
            transform: 'rotate(0)',
        },
        '100%': {
            transform: 'rotate(359deg)',
        },
    },
}));

export const DateFilter = forwardRef((props, ref) => {
    const classes = useStyles();

    const {
        selectedStartDate,
        setSelectedStartDate,
        selectedEndDate,
        setSelectedEndDate,
        handleOnSearch,
        row = true,
        extraIF,
        format,
        loading,
        border
    } = props;

    return (
        <Box className={row ? classes.row : ''}>
            <SimpleCard border={props.border} elevation={0}>
                {/* <TextField id='outlined-basic' label='Outlined' variant='outlined' /> */}
                {extraIF && (
                    <TextField
                        id='outlined-number'
                        label={extraIF}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant='outlined'
                        className={classes.marginRight}
                        inputRef={ref}
                    />
                )}
                <DatePicker
                    disableFuture
                    id='start-date'
                    openTo='date'
                    format={format || 'MM/dd/yyyy'}
                    label='Start date'
                    views={['year', 'month', 'date']}
                    value={selectedStartDate.toISOString()}
                    inputVariant='outlined'
                    onChange={setSelectedStartDate}
                    style={{ marginRight: 20, flex: 1 }}
                />
                <DatePicker
                    disableFuture
                    id='end-date'
                    openTo='date'
                    format={format || 'MM/dd/yyyy'}
                    label='End date'
                    views={['year', 'month', 'date']}
                    value={selectedEndDate}
                    inputVariant='outlined'
                    onChange={setSelectedEndDate}
                    style={{ marginRight: 20, flex: 1 }}
                    minDate={selectedStartDate}
                />
                <Button
                    fullWidth
                    variant='contained'
                    color='primary'
                    onClick={handleOnSearch}
                    disabled={loading}
                    style={{ flex: 1, maxWidth: 100, padding: '7px 7px' }}
                >
                    {(loading && <LoopIcon classes={{ root: classes.icon }} />) || 'Search'}
                </Button>
            </SimpleCard>
        </Box>
    );
});

DateFilter.propTypes = {
    selectedStartDate: PropTypes.any.isRequired,
    setSelectedStartDate: PropTypes.func.isRequired,
    handleOnSearch: PropTypes.func.isRequired,
    selectedEndDate: PropTypes.any.isRequired,
    setSelectedEndDate: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
};
