import { snackBarAction } from '../Redux/actions/utilitiesAction';

export const errorHandler = (error, type = 'error', customMsg = null) =>
    snackBarAction({
        open: true,
        msg:
            customMsg ||
            error?.response?.message ||
            error?.response?.data?.description ||
            error?.response?.data?.message ||
            'Internal Error',
        type,
    });
