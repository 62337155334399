import { combineReducers } from 'redux';
import signInReducer from './signInReducer';
import utilitiesReducer from './utilitiesReducer';
import callReducer from './callReducer';
import userReducer from './userReducer';
import miscallReducer from './miscallRducer';
import callTrackingReducer from './callTrackingReducer';
import forgotPasswordReducer from "./forgotPasswordReducer"

const rootReducer = combineReducers({
    signInReducer,
    utilitiesReducer,
    callReducer,
    userReducer,
    miscallReducer,
    callTrackingReducer,
    forgotPasswordReducer,
});

export default rootReducer;
