import { GET_ALL_MISSCALL_START, GET_ROICHANNEL_START } from '../../consts/actionTypes';

export const miscallAction = (from, to, didNumber) => ({
    type: GET_ALL_MISSCALL_START,
    from,
    to,
    didNumber,
});

export const roiCallAction = (from, to, didNumber = 0) => ({
    type: GET_ROICHANNEL_START,
    from,
    to,
    didNumber,
});
