import { put, call, takeLatest, select } from 'redux-saga/effects';
import {
    GET_ALL_MISSCALL_START,
    GET_ALL_MISSCALL_COMPLETE,
    GET_ROICHANNEL_START,
    GET_ROICHANNEL_COMPLETE,
} from '../../consts/actionTypes';
import { doesFilterRequestAction } from '../actions/utilitiesAction';
import { tokenSelector } from '../selectors';
import { apiCall } from '../../consts/funtions';
import { MISCALL, ROICALLS } from '../../consts/url';
import { organization } from '../../consts/organization';
import { errorHandler } from '../../consts/errorHandler';
import tz from '../../consts/timezone';

function* fetchMiscalls({ from, to, didNumber }) {
    yield put(doesFilterRequestAction(true));

    try {
        const token = yield select(tokenSelector);
        const results = yield call(
            apiCall,
            `${MISCALL}/${organization().name}/${from}/${to}/${tz}`,
            null,
            token,
            'GET'
        );

        yield put({ type: GET_ALL_MISSCALL_COMPLETE, payload: results.data });
    } catch (error) {
        yield put(errorHandler(error));
    }

    yield put(doesFilterRequestAction(false));
}

function* fetchROIChannel({ from, to, didNumber }) {
    yield put(doesFilterRequestAction(true));
    try {
        const token = yield select(tokenSelector);
        const results = yield call(
            apiCall,
            `${ROICALLS}/${organization().name}/${from}/${to}/${tz}`,
            null,
            token,
            'GET'
        );

        yield put({ type: GET_ROICHANNEL_COMPLETE, payload: results.data });
    } catch (error) {
        yield put(errorHandler(error));
    }
    yield put(doesFilterRequestAction(false));
}

export default function* miscallWatcher() {
    yield takeLatest(GET_ALL_MISSCALL_START, fetchMiscalls);
    yield takeLatest(GET_ROICHANNEL_START, fetchROIChannel);
}
