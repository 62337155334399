import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { RouteWithLayout, MainLayout } from './components';

import {
    SignIn as SignInView,
    Main as MainView,
    Dashboard as DashboardView,
    Dashboard2 as Dashboard2View,
    Chat as ChatView,
    Misscalled as MisscalledView,
    ROIChannel as ROIChannelView,
    CallTracking as CallTrackingView,
    ForgotPassword as ForgotPasswordView,
} from './Views';

const routes = () => {
    return (
        <Switch>
            <Redirect exact from='/' to='/sign-in' />
            <Route exact path='/sign-in'>
                <SignInView />
            </Route>
            <Route exact path='/forgot-password'>
                <ForgotPasswordView />
            </Route>
            <RouteWithLayout component={MainView} exact layout={MainLayout} path='/calls' />
            {/* <RouteWithLayout
                component={DashboardView}
                exact
                layout={MainLayout}
                path='/dashboard'
            /> */}
            <RouteWithLayout
                component={Dashboard2View}
                exact
                layout={MainLayout}
                path='/dashboard'
            />
            <RouteWithLayout component={ChatView} exact layout={MainLayout} path='/ChatView' />
            <RouteWithLayout
                component={MisscalledView}
                exact
                layout={MainLayout}
                path='/missedcalls'
            />
            <RouteWithLayout
                component={ROIChannelView}
                exact
                layout={MainLayout}
                path='/roichannel'
            />
            <RouteWithLayout
                component={CallTrackingView}
                exact
                layout={MainLayout}
                path='/calltracking'
            />
            <Redirect to='/not-found' />
        </Switch>
    );
};
export default routes;
