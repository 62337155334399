import { createTheme } from '@material-ui/core/styles';
import {organization} from './organization'

const {primary, secondary} = organization().theme

const theme = createTheme({
    palette: {
        primary: {
            main: primary
        },
        secondary: {
            main: secondary
        },
        white: '#fff',
        gray: '#cccccc',
        lightGray: '#f2f2f2',
        lightestGray: '#F9F9F9',
        red: '#f02711',
    },
});

export default theme;

export function getOrganizationSpecificStyles() {
    const organizationName = organization().name;
    switch (organizationName) {
        case 'jmhonda':
            return {
                background: '#F7DADB',
                border: '19px solid #ee3e44',
            };
        case 'xprintx':
            return {
                background: '#F0F0F0',
                border: '19px solid #2c2e83',
            };
        default:
            return {
                background: '#e6f7ff',
                border: '19px solid #00affe',
            };
    }
}

export function getAppBarBackgroundColor(theme) {
    const organizationName = organization().name;
    if (organizationName === 'jmhonda' || organizationName === 'xprintx') {
        return theme.palette.primary.main;
    } else {
        return theme.palette.secondary.main;
    }
}