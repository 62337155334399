import { formatNumber } from '../consts/funtions';

export const getDateInFormat = (date, isReverse = null) => {
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();

    if (isReverse) return `${formatNumber(month + 1)}/${formatNumber(day)}/${formatNumber(year)}`;
    return `${formatNumber(year)}-${formatNumber(month + 1)}-${formatNumber(day)}`;
};
