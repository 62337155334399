import { forgotPasswordCompleteAction,forgotPasswordStartAction,forgotPasswordFailAction } from "../actions/forgotPasswordAction";
import { call, put, takeLatest } from "redux-saga/effects";

import { SNACKBAR } from "../../consts/actionTypes";

import { REQUEST_PASSWORD_TOKEN,SAVE_NEW_PASSWORD } from "../../consts/actionTypes";
import { apiCall } from "../../consts/funtions";

function* forgotPasswordSaga({ payload }) {
    yield put(forgotPasswordStartAction())
    try {
        const results = yield call(apiCall, "forgot_password", payload, null, "POST");
        yield put({ type: SNACKBAR, payload: { open: true, msg: results?.data?.message, type: 'success' } })
        yield put(forgotPasswordCompleteAction(results));
    } catch (error) {
        yield put(forgotPasswordFailAction({error: "Email address does not exist."}));
        yield put ({type: SNACKBAR, payload: {open: true, msg: "Email address does not exist.", type: 'error'}})
    }
    return; 
}

function* resetPasswordSaga({ payload }) {
    yield put(forgotPasswordStartAction())
    try {
        const results = yield call(apiCall, 'recovery_change_password', payload, null, 'POST');
        if (results.data?.message.includes('Invalid token')) 
            yield put({ type: SNACKBAR, payload: { open: true, msg: results?.data?.message, type: 'error' } })
        
        if(results.status === 201) {
            yield put({ type: SNACKBAR, payload: { open: true, msg: results?.data?.message, type: 'success' } })
            window.location.href = '/sign-in';
        }
        yield put(forgotPasswordCompleteAction(results));
    } catch (error) {
        yield put(forgotPasswordFailAction({error: "Something went wrong. Please try again."}));
        yield put ({type: SNACKBAR, payload: {open: true, msg: "Someting went wrong. Please try again", type: 'error'}})
      }
      return
}

export default function* forgotPasswordWatcher() {
    yield takeLatest(REQUEST_PASSWORD_TOKEN, forgotPasswordSaga);
    yield takeLatest(SAVE_NEW_PASSWORD, resetPasswordSaga);
}