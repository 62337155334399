import { all } from 'redux-saga/effects';
import signIn from './signInSaga';
import calls from './callSaga';
import user from './userSaga';
import getDataOnPageLoad from './localSaga';
import miscall from './misscalledSaga';
import callTracking from './callTrackingSaga';
import forgotPasswordWatcher from "./forgotPasswordSaga";

export default function* rootSaga() {
    yield all([
        signIn(),
        calls(),
        user(),
        getDataOnPageLoad(),
        miscall(),
        callTracking(),
        forgotPasswordWatcher(),
    ]);
}
