import React from 'react';
import { CircularProgress, Backdrop } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { isLoadingBackdropSelector } from '../../Redux/selectors';

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        background: 'rgba(255,255,255,0.5)',
    },
}));
const BackdropProgress = () => {
    const isLoadingState = useSelector((state) => isLoadingBackdropSelector(state));
    const classes = useStyles();
    return (
        <Backdrop className={classes.backdrop} open={isLoadingState}>
            <CircularProgress size={75} />
        </Backdrop>
    );
};

export default BackdropProgress;
