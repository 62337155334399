import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    recentsCallListByExtSelector,
    extSelector,
    doesFilterRequestSelector,
} from '../../Redux/selectors';
import { recentsCallsFilterAction } from '../../Redux/actions/callAction';

import { getDateInFormat } from '../../utils/';
import { DateFilter } from '../../components';
import View from './view';

const Index = () => {
    const recentsCallListState = useSelector((state) => recentsCallListByExtSelector(state));
    const extState = useSelector((state) => extSelector(state));
    const dispatch = useDispatch();
    const [expanded, setExpanded] = useState(-1);
    const [selectedStartDate, setSelectedStartDate] = useState(new Date());
    const [selectedEndDate, setSelectedEndDate] = useState(new Date());
    const isFilterRequested = useSelector((state) => doesFilterRequestSelector(state));

    useEffect(() => {
        const today = new Date();
        const firstDateOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
        setSelectedStartDate(firstDateOfMonth);

        dispatch(
            recentsCallsFilterAction(
                getDateInFormat(firstDateOfMonth),
                getDateInFormat(selectedEndDate)
            )
        );
    }, []);

    const handleOnSearch = () => {
        dispatch(
            recentsCallsFilterAction(
                getDateInFormat(selectedStartDate),
                getDateInFormat(selectedEndDate)
            )
        );
    };

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : -1);
    };

    const paginationHandler = (event, page) => {
        dispatch(
            recentsCallsFilterAction(
                getDateInFormat(selectedStartDate),
                getDateInFormat(selectedEndDate),
                page
            )
        );
    };

    return (
        <View
            recentsCallListState={recentsCallListState}
            expanded={expanded}
            handleChange={handleChange}
            extState={extState}
            paginationHandler={paginationHandler}
            isLoading={isFilterRequested}
            dateFilterComp={
                <DateFilter
                    handleOnSearch={handleOnSearch}
                    setSelectedStartDate={setSelectedStartDate}
                    selectedStartDate={selectedStartDate}
                    selectedEndDate={selectedEndDate}
                    setSelectedEndDate={setSelectedEndDate}
                    row={false}
                    loading={isFilterRequested}
                />
            }
        />
    );
};

export default Index;
