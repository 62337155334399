import React from 'react';
import ContentLoader from 'react-content-loader';

const TableLoader = (props) => (
    <ContentLoader
        width={1000}
        height={550}
        viewBox='0 0 1000 550'
        backgroundColor='#eaeced'
        foregroundColor='#ffffff'
        {...props}
    >
        <circle cx='879' cy='123' r='11' />
        <circle cx='914' cy='123' r='11' />
        <rect x='104' y='115' rx='3' ry='3' width='141' height='15' />
        <rect x='305' y='114' rx='3' ry='3' width='299' height='15' />
        <rect x='661' y='114' rx='3' ry='3' width='141' height='15' />
        <rect x='55' y='155' rx='3' ry='3' width='897' height='2' />
        <circle cx='880' cy='184' r='11' />
        <circle cx='915' cy='184' r='11' />
        <rect x='105' y='176' rx='3' ry='3' width='141' height='15' />
        <rect x='306' y='175' rx='3' ry='3' width='299' height='15' />
        <rect x='662' y='175' rx='3' ry='3' width='141' height='15' />
        <rect x='56' y='216' rx='3' ry='3' width='897' height='2' />
        <circle cx='881' cy='242' r='11' />
        <circle cx='916' cy='242' r='11' />
        <rect x='106' y='234' rx='3' ry='3' width='141' height='15' />
        <rect x='307' y='233' rx='3' ry='3' width='299' height='15' />
        <rect x='663' y='233' rx='3' ry='3' width='141' height='15' />
        <rect x='57' y='274' rx='3' ry='3' width='897' height='2' />
        <circle cx='882' cy='303' r='11' />
        <circle cx='917' cy='303' r='11' />
        <rect x='107' y='295' rx='3' ry='3' width='141' height='15' />
        <rect x='308' y='294' rx='3' ry='3' width='299' height='15' />
        <rect x='664' y='294' rx='3' ry='3' width='141' height='15' />
        <rect x='58' y='335' rx='3' ry='3' width='897' height='2' />
        <circle cx='881' cy='363' r='11' />
        <circle cx='916' cy='363' r='11' />
        <rect x='106' y='355' rx='3' ry='3' width='141' height='15' />
        <rect x='307' y='354' rx='3' ry='3' width='299' height='15' />
        <rect x='663' y='354' rx='3' ry='3' width='141' height='15' />
        <rect x='57' y='395' rx='3' ry='3' width='897' height='2' />
        <circle cx='882' cy='424' r='11' />
        <circle cx='917' cy='424' r='11' />
        <rect x='107' y='416' rx='3' ry='3' width='141' height='15' />
        <rect x='308' y='415' rx='3' ry='3' width='299' height='15' />
        <rect x='664' y='415' rx='3' ry='3' width='141' height='15' />
        <rect x='55' y='453' rx='3' ry='3' width='897' height='2' />
        <circle cx='882' cy='484' r='11' />
        <circle cx='917' cy='484' r='11' />
        <rect x='107' y='476' rx='3' ry='3' width='141' height='15' />
        <rect x='308' y='475' rx='3' ry='3' width='299' height='15' />
        <rect x='664' y='475' rx='3' ry='3' width='141' height='15' />
    </ContentLoader>
);

TableLoader.metadata = {
    name: 'Sridhar Easwaran',
    github: 'sridhareaswaran',
    description: 'Loader for Tables',
    filename: 'TableLoader',
};

export default TableLoader;
