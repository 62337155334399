export const URL_API = 'https://api.heydayphone.com/';
export const AUTH = 'auth';
export const RECENTS = 'recents';
export const RECENTS_ORG = 'organization_recents';
export const USER = 'user';
export const PIECHARTDATA = 'dashboard';
export const LINECHARTDATA = 'calls_inbound_outbound_graph_lines_year';
export const COLOR_BY_ORGANITATION = 'organizationbyname';
export const LINECHARTDATA_MONTH = 'calls_inbound_outbound_graph_lines_month';
export const MISCALL = 'missed_calls';
export const ROICALLS = 'roi_calls';
export const CALLRECORD = 'call_record_download';
export const CALL_TRANSCRIPT = 'call_transcript';
export const CALL_TRACKING = 'call_tracking';
