import { put } from 'redux-saga/effects';
import { getLocalStorage } from '@lifeeric/react-localstroage';
import {
    SIGN_IN_COMPLETE,
    COLORS,
    ACCESS_TOKEN,
    ACCESS_NUMBER,
    ORGANIZATION_COLOR,
} from '../../consts/actionTypes';
import { isLoadingViewAction } from '../actions/utilitiesAction';
import { organization } from '../../consts/organization';

export default function* getDataOnPageLoad() {
    yield put(isLoadingViewAction(true));
    const gotToken = yield getLocalStorage(organization().name + ACCESS_TOKEN);
    const accessNumber = yield getLocalStorage(organization().name + ACCESS_NUMBER);
    const colors = yield getLocalStorage(organization().name + ORGANIZATION_COLOR);

    const data = {
        access_token: gotToken,
        access_number: accessNumber,
        organization: organization().name,
    };

    yield put({ type: SIGN_IN_COMPLETE, payload: { data } });
    yield put({ type: COLORS, payload: JSON.parse(colors) });
    yield put(isLoadingViewAction(false));
}
