import React, { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import Routes from './Routes';
import theme from './consts/theme';
import { ThemeProvider } from '@material-ui/styles';
import { organization } from './consts/organization';

const App = () => {
    console.log(
        'DOMAIN =>',
        window.location.host,
        window.location.hostname,
        ' ==== ',
        window.location
    );

    const orgInfo = organization();

    useEffect(() => {
        const favicon = document.querySelector('link[rel="icon"]');
        const title = document.querySelector('title');
    
        if (orgInfo) {
          if (favicon) {
            if (orgInfo.name === 'sdkphone') {
              favicon.href = '/sdkphone.ico';
            } else if (orgInfo.name === 'heydayphone') {
              favicon.href = '/callmephone.ico';
            } else if (orgInfo.name === 'jmhonda') {
              favicon.href = '/jmhonda.ico';
            } else if (orgInfo.name === 'xprintx') {
              favicon.href = '/xprintx.ico';
            } else {
              favicon.href = '/callmephone.ico';
            }
          }

          if (title) {
            if (orgInfo.name === 'sdkphone') {
              title.textContent = 'SDK Phone';
            } else if (orgInfo.name === 'heydayphone') {
              title.textContent = 'Call Me Phone';
            } else if (orgInfo.name === 'jmhonda') {
              title.textContent = 'JM Honda';
            } else if (orgInfo.name === 'xprintx') {
              title.textContent = 'XPrintx';
            } else {
              title.textContent = 'Call Me Phone'; // Default title
            }
          }
        }
      }, [orgInfo]);
    return (
        <ThemeProvider theme={theme}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Router>
                    <Routes />
                </Router>
            </MuiPickersUtilsProvider>
        </ThemeProvider>
    );
};
export default App;
