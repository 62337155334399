/** @format */

import React, { forwardRef, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles, Typography, Box, useTheme, withStyles } from '@material-ui/core/';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import ContentLoader from 'react-content-loader';
import LoopIcon from '@material-ui/icons/Loop';
import { SvgIcon } from '@material-ui/core';

import { collapseLoadingAction } from '../../Redux/actions/callTrackingAction';
import { DateFilter, SkeletonCallLog, AudioPlayerCustom, Pagination } from '../../components';
import { getOrganizationSpecificStyles } from '../../consts/theme';
import { InboxOutlined } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    container: {
        flex: 1,
        flexDirection: 'column',
        overflow: 'auto',
        padding: theme.spacing(2),
    },

    root: {
        width: '98.4%',
        margin: '10px',
    },
}));

const useRowStyles = makeStyles({
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
    scrollX: {
        overflowX: 'auto',
        maxWidth: '100%',
    },
    bold: {
        fontWeight: '900',
    },
    icon: {
        animation: '$rotateIcon 1s infinite linear',
        position: 'relative',
        left: '500%',
    },
    '@keyframes rotateIcon': {
        '0%': {
            transform: 'rotate(0)',
        },
        '100%': {
            transform: 'rotate(359deg)',
        },
    },
});

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "black",
    color: "white",
  }
}))(TableCell);

function Row(props) {
    const { idx, call, getCallLogsHandler } = props;
    const theme = useTheme();
    const classes = useRowStyles();
    const dispatch = useDispatch();

    console.log('[isLoading]', call.isCollapseOpen);

    const handleIsOpen = () => {
        const currentIdx = props.pageSize * (props.currentPage - 1) + idx;
        dispatch(collapseLoadingAction(currentIdx, !call?.isCollapseOpen));
    };

    return (
        <React.Fragment>
            <TableRow className={classes.root}>
                <TableCell>
                    <IconButton aria-label='expand row' size='small' onClick={handleIsOpen}>
                        {call?.isCollapseOpen ? (
                            <KeyboardArrowUpIcon />
                        ) : (
                            <KeyboardArrowDownIcon
                                onClick={
                                    call?.logs ? () => {} : getCallLogsHandler.bind(null, call.cnum)
                                }
                            />
                        )}
                    </IconButton>
                </TableCell>
                <TableCell align='left' style={{ color: theme.palette.red }}>
                    {call?.cnum} - {call?.cnam}
                </TableCell>
                <TableCell align='left'>{call?.description}</TableCell>
                <TableCell align='left'>{call?.dst}</TableCell>
                <TableCell align='left'>{call?.status === 'ANSWERED' ? "ANSWERED" : "NOT ANSWERED"}</TableCell>
                <TableCell align='left'>
                    {call?.status === 'ANSWERED' ? 'No action required' : 'We have to call back'}
                </TableCell>
                <TableCell align='left'>{call?.note}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0, paddingLeft: 0 }} colSpan={6}>
                    <Collapse in={call?.isCollapseOpen} timeout='auto' unmountOnExit>
                        <Box className={classes.scrollX} style={{
                            background: getOrganizationSpecificStyles().background,
                            padding: 10,
                            borderLeft: getOrganizationSpecificStyles().border,
                            borderRadius: '5px 0px 0px 5px'
                        }}>
                            <Typography variant='h6' gutterBottom component='div'>
                                History
                            </Typography>
                            <Table size='small' aria-label='purchases'>
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.bold}>DID Number</TableCell>
                                        <TableCell className={classes.bold}>Source</TableCell>
                                        <TableCell className={classes.bold}>Destination</TableCell>
                                        <TableCell className={classes.bold}>Duration</TableCell>
                                        <TableCell className={classes.bold}>Date</TableCell>
                                        <TableCell className={classes.bold}>Time</TableCell>
                                        <TableCell className={classes.bold}>Caller Name</TableCell>
                                        <TableCell className={classes.bold}>Caller Number</TableCell>
                                        <TableCell className={classes.bold}>Description</TableCell>
                                        <TableCell className={classes.bold}>Status</TableCell>
                                        <TableCell className={classes.bold}>Recording call</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {call?.logs?.map((log) => (
                                        <TableRow key={log?.calldate_time + Math.random() * 999}>
                                            <TableCell
                                                component='th'
                                                scope='row'
                                                style={{ color: 'green' }}
                                            >
                                                {log?.did}
                                            </TableCell>
                                            <TableCell>{log?.src}</TableCell>
                                            <TableCell>{log?.dst}</TableCell>
                                            <TableCell>{log?.duration}</TableCell>
                                            <TableCell>{log?.calldate_date}</TableCell>
                                            <TableCell>{log?.calldate_time}</TableCell>
                                            <TableCell>{log?.cnam}</TableCell>
                                            <TableCell>{log?.cnum}</TableCell>
                                            <TableCell>{log?.dst_cnam}</TableCell>
                                            <TableCell>{log?.status === "ANSWERED" ? "ANSWERED" : "NOT ANSWERED"}</TableCell>
                                            <TableCell>
                                                <AudioPlayerCustom src={log?.recordingfile}  width="250px" />
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                    {!call?.logs?.length && !call?.isLoading && (
                                           <Box className={classes.noDataContainer} sx={{ display: "grid", justifyItems:"center", alignItems: "center", marginTop: "1rem", paddingBottom: "2rem" }}>
                                           <InboxOutlined className={classes.icon} />
                                           <Typography
                                               variant="h5"
                                               component="h2"
                                               align="center"
                                               className={classes.text}
                                           >
                                               No data found
                                           </Typography>
                                           <Typography
                                               variant="body1"
                                               color="textSecondary"
                                               align="center"
                                           >
                                               There are no items to display at the moment
                                           </Typography>
                                       </Box>
                                    )}
                                </TableBody>
                                {call?.isLoading && (
                                    <TableBody>
                                        <LoopIcon classes={{ root: classes.icon }} />
                                    </TableBody>
                                )}
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

export const View = forwardRef((props, ref) => {
    const {
        handleOnSearch,
        selectedStartDate,
        setSelectedStartDate,
        selectedEndDate,
        setSelectedEndDate,
        callTracking,
        loading,
        getCallLogsHandler,
    } = props;

    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const theme = useTheme();
    
    const startIndex = page * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    const callTrackingPageChangingData = (callTracking || []).slice(startIndex, endIndex);

    const handlePaginationChange = (event, newPage) => {
      if (event.target.value === 'rowsPerPage') {
          setRowsPerPage(event.target.value);
          setPage(0);
      } else {
          setPage(newPage);
      }
  };

    return (
        <div className={classes.container}>
            <DateFilter
                handleOnSearch={handleOnSearch}
                selectedStartDate={selectedStartDate}
                setSelectedStartDate={setSelectedStartDate}
                selectedEndDate={selectedEndDate}
                setSelectedEndDate={setSelectedEndDate}
                extraIF={'Extension'}
                ref={ref}
                format='MM/dd/yyyy'
                loading={loading}
            />

            <Paper>
                <TableContainer style={{borderRadius: "4px"}}>
                    <Table aria-label='collapsible table'>
                        <TableHead>
                            <TableRow>
                                <StyledTableCell align='left'>Call Details</StyledTableCell>
                                <StyledTableCell align='left'>Details</StyledTableCell>
                                <StyledTableCell align='left'>Description</StyledTableCell>
                                <StyledTableCell align='left'>DID/Ring Group</StyledTableCell>
                                <StyledTableCell align='left'>Last Update</StyledTableCell>
                                <StyledTableCell align='left'>Status</StyledTableCell>
                                <StyledTableCell align='left'>Note</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {callTrackingPageChangingData?.map((call, idx) => (
                                <Row
                                    idx={idx}
                                    key={idx * 99999}
                                    currentPage={page + 1}
                                    pageSize={rowsPerPage}
                                    getCallLogsHandler={getCallLogsHandler}
                                    call={call}
                                />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                {!callTracking?.length && (
                       <Box className={classes.noDataContainer} sx={{ display: "grid", justifyItems:"center", alignItems: "center", marginTop: "1rem", paddingBottom: "2rem" }}>
                       <InboxOutlined className={classes.icon} />
                       <Typography
                           variant="h5"
                           component="h2"
                           align="center"
                           className={classes.text}
                       >
                           No data found
                       </Typography>
                       <Typography
                           variant="body1"
                           color="textSecondary"
                           align="center"
                       >
                           There are no items to display at the moment
                       </Typography>
                   </Box>
                )}
            </Paper>
        <Pagination
          count={Math.ceil(callTracking?.length / rowsPerPage) || 1}
          page={page + 1}
          onChange={(event, newPage) => handlePaginationChange(event, newPage - 1)}
          variant="outlined"
          position="right"
          color={"#fd681"}
        />
        </div>
    );
});

View.propTypes = {
    handleOnSearch: PropTypes.func.isRequired,
    selectedStartDate: PropTypes.object.isRequired,
    setSelectedStartDate: PropTypes.func.isRequired,
    selectedEndDate: PropTypes.object.isRequired,
    setSelectedEndDate: PropTypes.func.isRequired,
    callTracking: PropTypes.PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired,
};
