import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { organization } from "../../consts/organization";
import View from "./view";

import { useDispatch, useSelector } from "react-redux";
import { requestPasswordTokenAction, saveNewPasswordAction } from "../../Redux/actions/forgotPasswordAction";


const Index = () => {
    const dispatch = useDispatch();
    const { isTokenSend,isLoading } = useSelector((state) => state.forgotPasswordReducer);

    const [forgotPassword, setForgotPassword] = useState({
        email: "",
        token: "",
        password: "",
        confirmPassword: "",
        organization: organization().name,
    });

    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const handleOnChangeSignIn = (e) => {
        setForgotPassword({
            ...forgotPassword,
            [e.target.name]: e.target.value,
        });
    };
    const handleOnSummit = () => {
        if (isLoading) return;
        if (!isTokenSend)
            dispatch(
                requestPasswordTokenAction({
                    email: forgotPassword.email.toLocaleLowerCase(),
                    organization: forgotPassword.organization,
                })
            );
        else {
            if (forgotPassword.password !== forgotPassword.confirmPassword) {
                dispatch({
                    type: "SNACKBAR",
                    payload: { open: true, msg: "Password does not match", type: "error" },
                });
                return;
            }
            dispatch(
                saveNewPasswordAction({
                    email: forgotPassword.email.toLocaleLowerCase(),
                    token_recovery_password: forgotPassword.token,
                    new_password: forgotPassword.password,
                    organization: forgotPassword.organization,
                })
            );
        }
    };

    const handleClickShowPassword = () => {
        setShowPassword((showPassword) => !showPassword);
    };

    const handleClickShowConfirmPassword = () => {
        setShowConfirmPassword((showConfirmPassword) => !showConfirmPassword);
    };
    
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    const handleOnEnter = (e) => {
        if (e.key === "Enter") {
            handleOnSummit();
        }
    };

    return (
        <View
            forgotPassword={forgotPassword}
            handleOnChangeSignIn={handleOnChangeSignIn}
            handleOnEnter={handleOnEnter}
            showPassword={showPassword}
            handleClickShowPassword={handleClickShowPassword}
            handleMouseDownPassword={handleMouseDownPassword}
            handleOnSummit={handleOnSummit}
            isTokenSend={isTokenSend}
            showConfirmPassword={showConfirmPassword}
            handleClickShowConfirmPassword={handleClickShowConfirmPassword}
        />
    );
};

export default withRouter(Index);
