import React from 'react';
import { Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { useSelector, useDispatch } from 'react-redux';
import { snackBarSelector } from '../../Redux/selectors';
import { snackBarAction } from '../../Redux/actions/utilitiesAction';
const SnackbarAlert = () => {
    const snackBarState = useSelector((state) => snackBarSelector(state));
    const dispath = useDispatch();
    const closeSanckBar = () => {
        dispath(
            snackBarAction({
                open: false,
                msg: '',
                type: snackBarState.type,
            })
        );
    };
    return (
        <Snackbar
            open={snackBarState.open}
            autoHideDuration={3000}
            onClose={closeSanckBar}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        >
            <MuiAlert
                elevation={6}
                variant='filled'
                onClose={closeSanckBar}
                severity={snackBarState.type}
            >
                {snackBarState.msg}
            </MuiAlert>
        </Snackbar>
    );
};
export default SnackbarAlert;
