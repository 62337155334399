import {
    IS_LOADING_BACKDROP,
    SNACKBAR,
    IS_LOADING_VIEW,
    SEARCH_FILTER_REQUEST,
} from '../../consts/actionTypes';

export const isLoadingBackdropAction = (payload) => ({
    type: IS_LOADING_BACKDROP,
    payload,
});
export const isLoadingViewAction = (payload) => ({
    type: IS_LOADING_VIEW,
    payload,
});
export const snackBarAction = (payload) => ({
    type: SNACKBAR,
    payload,
});

export const doesFilterRequestAction = (payload) => ({
    type: SEARCH_FILTER_REQUEST,
    payload,
});
